import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FirstPage } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const PlanInversion = ({ 
  title, 
  subtitle, 
  iconSrc, 
  monthlyPrice,
  infoMonthlyPrice, 
  uniqueCostDescription, 
  uniqueCost, 
  implementationSteps, 
  footerNote,
  firsText,
  secondText,
  buttonLink,
  textButtonLink
}) => {
    const navigate = useNavigate();
  return (
    <Box className="gradientBox902" sx={{ px: { xs: 2, md: 1, lg: 15 }, py: { xs: 3, md: 5 } }} id={"plans"}>
      <AnimationOnScroll
        animateIn="animate__zoomIn animate__fadeIn"
        duration={0.75}
        animateOnce
        animatePreScroll={false}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Typography
          color="white"
          textAlign="center"
          sx={{
            pt: 5,
            fontSize: { xs: "30px", md: "40px" },
            lineHeight: 1.2,
            maxWidth: "600px",
          }}
        >
          <strong>{title}</strong>
          <br /> {subtitle}
        </Typography>
      </AnimationOnScroll>

      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "space-center" },
          flexDirection: { xs: "column", md: "row" },
          pt: 9,
          gap: 0,
        }}
      >
        <AnimationOnScroll 
        animateIn="animate__slideInUp" 
        duration={0.75} 
        delay={1200} 
        animateOnce>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              display="grid"
              sx={{
                borderRadius: 3,
                justifyContent: "center",
                alignContent: "center",
                px: { xs: 5, md: "50px" },
                pt: { xs: 5, md: "40px" },
                pb: { xs: 5, md: "10px" },
                pr: { md: "65px" },
                mr: { md: "-15px" },
                border: "1px solid #FFFFFF",
                color: "#FFFFFF",
                textAlign: "center",
                width: { xs: "530px", md: "auto" },
              }}
            >
              <Box sx={{ 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center" 
                }}>
                <img
                  src={iconSrc}
                  alt="icon"
                  style={{
                    filter:
                      "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)",
                    width: "55px",
                    height: "55px",
                  }}
                />
              </Box>

              <Typography sx={{ mt: 2 }}>
                <strong>Licencia mensual</strong>
              </Typography>
              <hr
                style={{
                  width: "95%",
                  borderColor: "#FFFFFF",
                  borderStyle: "solid",
                  borderWidth: "0.5px 0 0 0",
                }}
              />
              <Typography sx={{ mt: 2 }}>
                <strong>{monthlyPrice}</strong>
              </Typography>
              <Typography sx={{fontSize:"11px", maxWidth:"190px", mt:2}}>{infoMonthlyPrice}</Typography>
            </Box>
          </Box>
        </AnimationOnScroll>

        <AnimationOnScroll 
        animateIn="animate__slideInUp" 
        duration={0.75} 
        delay={1200} 
        animateOnce
        >
          <Box display="grid" sx={{ justifyContent: "center", pt: { xs: 5, md: 0 } }}>
            <Box
              gridRow="5/10"
              gridColumn="1/4"
              sx={{
                bgcolor: "#D9D9D9",
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: { xs: "530px", md: "600px" },
                py: "15px",
                px: { xs: "20px", md: "40px" },
              }}
            >
                <Typography
                textAlign="center"
                color="#162c44"
                fontFamily="bold"
                sx={{
                    lineHeight: 1,
                    fontSize: { xs: "18px", md: "20px" },
                    pt: 3,
                }}
                >
                    {uniqueCostDescription}
                </Typography>
                <Typography
                textAlign="center"
                color="#162c44"
                fontFamily="bold"
                sx={{
                    lineHeight: 1,
                    fontSize: { xs: "18px", md: "20px" },
                    py:2
                   
                }}
                >
                    {uniqueCost}
                </Typography>
                <Box>
                    <Typography
                    textAlign={"center"}
                    sx={{
                        lineHeight: 1.3,
                        pb: 1,
                        px: { xs: 2, sm: 2, md: 2 },
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#162C44",
                    }}
                    >
                        {firsText}
                    </Typography>
                </Box>
                <Box  sx={{ maxWidth: { xs: "350px", md: "460px" } }}>
                    <hr style={{ width: "95%", borderColor: "#5C6C7C", borderStyle: "solid" }}></hr>
                    {implementationSteps.map((step, index) => (
                    <Box key={index} sx={{ display: "flex", py: 0.5 }}>
                    <CheckCircleIcon
                    sx={{
                        borderRadius: "50%",
                        bgcolor: "#D9D9D9",
                        color: "#8A95A1",
                        mx: 1,
                        width: "17px",
                    }}
                    />
                    <Typography sx={{ pt: "2px", fontSize: "12px", color: "#162C44" }}>
                        <strong>{step.title}</strong>
                        <br /> {step.description}
                    </Typography>
                  </Box>
                ))}
                <hr style={{ width: "95%", borderColor: "#5C6C7C", borderStyle: "solid" }}></hr>
              </Box>
              <Box >
                    <Typography
                        textAlign={"justify"}
                        sx={{
                            lineHeight: 1.3,
                            py: 1,
                            px: { xs: 2, sm: 2, md: 2 },
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#162C44",
                        }}
                    >
                        {secondText}
                    </Typography>
              </Box>
              <Button
                onClick={() => navigate(buttonLink)}
                sx={{
                  borderRadius: 2,
                  px: 2,
                  alignSelf: "center",
                  fontSize: "12px",
                  bgcolor: "#162c44",
                  "&:hover": { bgcolor: "#5C6C7C" },
                  my: 1,
                }}
              >
                {textButtonLink}
              </Button>
            </Box>
          </Box>
        </AnimationOnScroll>
      </Box>

      <AnimationOnScroll animateIn="animate__zoomIn animate__fadeIn" duration={0.75} animateOnce>
        <Typography
          color="white"
          textAlign="center"
          sx={{
            pt: "60px",
            pb: 4,
            fontSize: { xs: "12px", md: "14px" },
            lineHeight: 1.3,
          }}
        >
          {footerNote}
        </Typography>
      </AnimationOnScroll>
    </Box>
  );
};

export default PlanInversion;
