import { Box, Typography, Grid, Stack, Button, Collapse, Fade, Slide, IconButton } from "@mui/material";
import { NavBar, Footer, ModuleCategoryCard } from "../components";
import { ThemeConfig } from "../config/theme.config";
import "./gradientBoxes.css";
import { ScrollToTopButton } from "../components/scrollToTopButton";
import principles from "../const/principlesContent";
import CompromisesList from "../components/compromisesList";
import compromisesContent from "../const/compromisesContent";
import { useRef, useState, useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { fontGrid } from "@mui/material/styles/cssUtils";
import VideoContainerAbout from "../components/VideoContainerAbout";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useWindowSize from "../lib/hooks/useWindowSize";

export const AboutUsScreen = () => {
   const misionVisionRef = useRef(null);
   // const [open, setOpen] = useState(false);
   // useEffect(() => {
   //    const timer = setTimeout(() => {
   //       setOpen(true);
   //    }, 1000);
   //    return () => clearTimeout(timer);
   // }, []);
   const [showContent, setShowContent] = useState(false);
   const toggleContent = () => {
      setShowContent(!showContent);
   };
   const width = useWindowSize();
   const isSmallScreen = width <= 900;

   const backgroundStyle = isSmallScreen
      ? {
           backgroundImage: showContent
              ? "none"
              : "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Nosotros/Fondo+desplegable+nosotros.png)",
        }
      : {
           backgroundImage:
              "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Nosotros/Fondo+desplegable+nosotros.png)",
        };
   return (
      <ThemeConfig>
         <Box
            sx={{
               backgroundImage:
                  "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Fondo+beneficios.png)",
               backgroundPosition: "center top",
               backgroundSize: "cover",
               backgroundRepeat: "no-repeat",
               alignItems: "center",
               justifyContent: "center",
               alingSelf: "center",
               backgroundSize: "cover",
               height: "100%",
               pb: 5,
            }}
         >
            {/* <Box
               position={"absolute"}
               top={0}
               zIndex={-1}
               sx={{
                  width: "100%",
                  height: { xs: "650px", sm: "550px", md: "610px", lg: "700px" },
                  overflow: "hidden",
               }}
            >
               <video
                  className="gcBanner"
                  autoPlay
                  loop
                  muted
                  style={{
                     opacity: 0.3,
                     width: "100%",
                     height: "100%",
                     objectFit: "cover",
                     objectPosition: "top",
                  }}
                  sx={{}}
               >
                  <source
                     src={
                        "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Video+corporativo+Lecosy+2024.mp4"
                     }
                     type="video/mp4"
                  />
               </video>
            </Box> */}
            <NavBar />
            <Grid container>
               <VideoContainerAbout
                  videoUrl={
                     "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Video+corporativo+Lecosy+2024.mp4"
                  }
                  direction="row"
                  alternComponent={
                     <Slide in={true} appear timeout={50} direction="up">
                        <div>
                           <Fade in={true} appear timeout={50}>
                              <Stack
                                 direction={"column"}
                                 spacing={3}
                                 px={{ xs: 2, sm: 2, md: 0 }}
                                 sx={{ pt: { md: 4, lg: 5 } }}
                              >
                                 <Box>
                                    <Box sx={{ display: "flex", justifyContent: "right" }}>
                                       <Typography
                                          className="slideinBox"
                                          color={"primary"}
                                          sx={{
                                             fontSize: { xs: "35px", md: "50px" },
                                             fontFamily: "Bold",
                                             textAlign: "right",

                                             pr: { xs: 3, sm: 5, md: 4 },
                                             color: "white",
                                             width: { xs: "100%", md: "46vw" },
                                          }}
                                       >
                                          Quienes somos
                                       </Typography>
                                    </Box>
                                    <Box
                                       sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                       }}
                                    >
                                       <Typography
                                          sx={{
                                             textAlign: "justify",
                                             width: { xs: "90%", md: "90%" },
                                          }}
                                          fontSize={{ xs: "16px", lg: "18px" }}
                                          lineHeight={1.2}
                                       >
                                          LECOSY es un sistema dedicado a la gestión de riesgo y cumplimiento legal y
                                          fiscal, dirigido a todas aquellas empresas que desean establecer estándares de
                                          cumplimiento legal, vigilancia, control, ética y buenas prácticas
                                          empresariales, con el objetivo de prevenir, corregir, mitigar y/o erradicar
                                          los posibles riesgos a los que se encuentra expuesta las organizaciones.
                                          <br />
                                          <br />
                                          Nuestro principal enfoque es coadyuvar con las organizaciones cualquiera que
                                          sea su actividad económica y tamaño, en la implementación de acciones
                                          oportunas para mantener un debido control sobre la operatividad de sus
                                          negocios.
                                       </Typography>
                                    </Box>
                                 </Box>
                              </Stack>
                           </Fade>
                        </div>
                     </Slide>
                  }
               />
            </Grid>
            <Stack
               display={"flex"}
               direction={{ xs: "column", md: "row" }}
               ref={misionVisionRef}
               py={{ xs: 5 }}
               spacing={{ md: 2, lg: 10 }}
               sx={{ justifyContent: "center", alignContent: "center", pb: { xs: 0, md: 10 } }}
            >
               <Box flex={1}>
                  <Box flex={1} display={"flex"}>
                     <AnimationOnScroll
                        animateIn="animate__slideInLeft"
                        animateOnce
                        animatePreScroll={false}
                        duration={0.75}
                     >
                        <Box
                           sx={{
                              display: "flex",
                              minHeight: "260px",
                              backgroundColor: "#F7F7F7",
                              borderTopRightRadius: { md: "10px" },
                              borderBottomRightRadius: { md: "10px" },
                              // my: { xs: 2, md: 0 },
                           }}
                        >
                           <Box
                              sx={{
                                 minHeight: { md: "350px", lg: "280px" },
                                 display: "flex",
                                 alignItems: { xs: "center", md: "start" },
                                 flexDirection: "column",
                                 justifyContent: "center",
                                 borderTopRightRadius: 45,
                                 borderBottomRightRadius: 45,
                                 px: { xs: 5, sm: 10, md: 8, lg: 10 },
                                 // py: { xs: 4, md: 3, lg: 5 },
                              }}
                           >
                              <Typography
                                 color={"primary"}
                                 fontFamily={"bold"}
                                 sx={{
                                    maxWidth: "100%",
                                    display: "flex",
                                    fontSize: { xs: "30px", md: "40px" },
                                    // py: 1,
                                 }}
                              >
                                 Nuestro&nbsp;
                                 <Typography fontFamily={"ExtraBold"} sx={{ fontSize: { xs: "30px", md: "40px" } }}>
                                    camino
                                 </Typography>
                              </Typography>
                              <Typography color={"primary"} sx={{ mt: 1, textAlign: "justify", maxWidth: "100%" }}>
                                 Prevenir, corregir y ofrecer soluciones a contingencias legales, a través de mecanismos
                                 estructurados que otorguen certeza jurídica en materia de cumplimiento a las
                                 organizaciones.
                              </Typography>
                           </Box>
                        </Box>
                     </AnimationOnScroll>
                  </Box>
               </Box>
               <Box flex={1}>
                  <AnimationOnScroll
                     animateIn="animate__slideInRight"
                     animateOnce
                     animatePreScroll={false}
                     duration={0.75}
                     delay={750}
                  >
                     <Box
                        sx={{
                           minHeight: "260px",
                           // pt: 1,
                           backgroundColor: "#F7F7F7",
                           borderTopLeftRadius: { md: "10px" },
                           borderBottomLeftRadius: { md: "10px" },
                        }}
                     >
                        <Box
                           sx={{
                              minHeight: { xs: "200px", sm: "200px", md: "350px", lg: "280px" },
                              display: "flex",
                              alignItems: { xs: "center", md: "start" },
                              flexDirection: "column",
                              justifyContent: "center",
                              px: { xs: 5, sm: 10, md: 8, lg: 10 },
                              // py: { xs: 4, md: 3, lg: 5 },
                           }}
                        >
                           <Typography
                              lineHeight={1}
                              color={"primary"}
                              fontFamily={"bold"}
                              sx={{
                                 display: "flex",
                                 fontSize: { xs: "30px", md: "40px" },
                                 // py: 1,
                              }}
                           >
                              <Typography
                                 fontFamily={"Extrabold"}
                                 sx={{ lineHeight: 1, fontSize: { xs: "30px", md: "39px" } }}
                              >
                                 <strong style={{ fontFamily: "bold" }}> Hacia</strong> donde vamos
                              </Typography>
                           </Typography>
                           <Typography lineHeight={1.3} color={"primary"} sx={{ mt: 1, textAlign: "justify" }}>
                              Generar un impacto a nivel nacional en la gestión de riesgos y cumplimiento,
                              proporcionando herramientas tecnológicas adaptadas a las necesidades de la empresa, con el
                              soporte de profesionistas especializados en cada rama del derecho.
                           </Typography>
                        </Box>
                     </Box>
                  </AnimationOnScroll>
               </Box>
            </Stack>
         </Box>

         <Stack direction={"column"}  pb={5} display={"flex"}>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
               <Box className="gradientBox902" px={{ xs: 5, lg: 20 }} py={5}>
                  <AnimationOnScroll animateIn="animate__fadeInUp" animatePreScroll={false} animateOnce duration={0.75}>
                     <Typography
                        lineHeight={1.3}
                        color={"white"}
                        textAlign={"center"}
                        fontFamily={"italic"}
                        fontWeight={"lighter"}
                        fontSize={"16px"}
                     >
                        “<strong>En Lecosy,</strong> nuestro compromiso es ayudar a las organizaciones a proteger su
                        patrimonio y asegurar que sus esfuerzos y dedicación se enfoquen para lograr un debido
                        cumplimiento y prevenir riesgos legales. Para lograr nuestro compromiso, implementamos
                        soluciones disruptivas a través de la tecnología y en colaboración con nuestro equipo de
                        profesionistas dedicados hacia un mismo objetivo.”<br></br>
                     </Typography>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp" animatePreScroll={false} animateOnce duration={0.75}>
                     <Typography
                        color={"white"}
                        px={{ md: 5, lg: 5 }}
                        lineHeight={1.2}
                        textAlign={"center"}
                        fontSize={{ md: "16px" }}
                        fontWeight={"bold"}
                     >
                        <br></br>
                        <br></br>
                        Para nosotros, es esencial mantener y promover una sólida cultura de cumplimiento en todos los
                        niveles de nuestra organización, así como con nuestros clientes, proveedores, y demás partes
                        interesadas. 
                     </Typography>
                  </AnimationOnScroll>
               </Box>
               <Box
                  className="gradientBox903"
                  width={"86%"}
                  height={"15px"}
                  sx={{ borderTopLeftRadius: 15, borderBottomRightRadius: 15, zIndex: 1 }}
               />
            </Box>
            <Stack
               style={{ ...backgroundStyle }}
               sx={{
                  // backgroundImage:
                  //    "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Nosotros/Fondo+desplegable+nosotros.png)",
                  backgroundColor: "#E0E3E6",
                  backgroundPosition: "center 20%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  alignItems: "center",
                  justifyContent: "center",
                  alingSelf: "center",
                  height: "100%",

                  mt: "-15px",
               }}
            >
               {" "}
               <AnimationOnScroll animateIn="animate__fadeInUp" animatePreScroll={false} animateOnce duration={0.75}>
                  <Typography
                     px={{ xs: 5, sm: 10, md: 20, lg: 30 }}
                     sx={{ pt: { xs: 6, sm: 8 } }}
                     lineHeight={1.2}
                     textAlign={"center"}
                     fontSize={{ xs: "24px", sm: "32px" }}
                     fontFamily={"bold"}
                     color={"primary"}
                  >
                     Nos comprometemos a
                  </Typography>
               </AnimationOnScroll>{" "}
               <Box
                  sx={{
                     textAlign: { xs: "center" },
                     py: { xs: 0, sm: 1 },
                  }}
               >
                  <AnimationOnScroll
                     animateIn="animate__zoomIn"
                     animateOnce
                     animatePreScroll={false}
                     duration={0.75}
                     delay={400}
                  >
                     <Typography
                        sx={{
                           color: "#162C44",
                           fontSize: "10px",
                           transform: showContent ? "scale(0)" : "scale(1)",
                           transition: "transform 0.3s",
                           mb: -3,
                           mt: { xs: 0.5, sm: 0 },
                        }}
                     >
                        Ver más información
                     </Typography>
                     <IconButton
                        onClick={toggleContent}
                        sx={{
                           transform: showContent ? "rotate(180deg)" : "rotate(0deg)",
                           // transform: showContent ? "scale(0)" : "scale(1)",
                           transition: "transform 0.3s",
                           color: "#162C44", // Cambia el color del ícono
                        }}
                     >
                        <ExpandMoreIcon sx={{ fontSize: 50, strokeWidth: 2 }} /> {/* Tamaño y grosor */}
                     </IconButton>
                  </AnimationOnScroll>
               </Box>
               <Collapse in={showContent}>
                  <Box>
                     <Stack
                        direction={{ xs: "column", lg: "row", lineHeight: 1.2 }}
                        spacing={{ lg: 10 }}
                        px={{ xs: 6, md: 6, lg: 10 }}
                        pb={10}
                     >
                        <Grid container>
                           {compromisesContent.map((compromise, i) => {
                              if (i > 3) return undefined;
                              return (
                                 <Grid item xs={12} md={6} lg={12} sx={{ pr: { md: 2 } }} key={compromise}>
                                    <CompromisesList description={compromise} />
                                 </Grid>
                              );
                           })}
                        </Grid>
                        <Grid container>
                           {compromisesContent.map((compromise, i) => {
                              if (i < 4) return undefined;
                              return (
                                 <Grid item xs={12} md={6} lg={12} sx={{ pr: { md: 2 } }} key={compromise}>
                                    <CompromisesList description={compromise} />
                                 </Grid>
                              );
                           })}
                        </Grid>
                     </Stack>
                  </Box>
               </Collapse>
            </Stack>
         </Stack>

         <Stack direction={"column"} spacing={2} py={5}>
            <Typography
               textAlign={"center"}
               px={{ xs: 4, sm: 10, lg: 20 }}
               fontFamily={"Bold"}
               fontSize={"18px"}
               lineHeight={1.4}
            >
               Esta Política es el fundamento de nuestro Sistema de Gestión Integrado y es revisada periódicamente para
               garantizar su pertinencia y eficacia en la mejora continua de nuestros módulos y servicios. Todas las
               partes interesadas de Lecosy están comprometidas a cumplir y colaborar con esta política de manera
               conjunta para lograr nuestros objetivos de calidad, gobernanza, cumplimiento, seguridad y excelencia en
               el servicio.
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
               <Button
                  href="https://drive.google.com/file/d/1vKP8DAhChYLz9WyDleQRmzMa5ZG7kfEw/view?usp=drive_link"
                  target="_blank"
                  sx={{
                     minWidth: "150px",
                     borderRadius: 3,
                     "&:hover": { bgcolor: "#8A95A1" },
                     m: 4,
                     textAlign: "center",
                  }}
               >
                  <Typography>Código de ética</Typography>
               </Button>
               {/* <Button
                  href="https://drive.google.com/file/d/1vKP8DAhChYLz9WyDleQRmzMa5ZG7kfEw/view?usp=drive_link"
                  target="_blank"
                  sx={{
                     minWidth: "150px",
                     borderRadius: 3,
                     "&:hover": { bgcolor: "#8A95A1" },
                     m: 4,
                     textAlign: "center",
                  }}
               >
                  <Typography>Política de integridad</Typography>
               </Button> */}
            </Box>
         </Stack>
         <Box
            sx={{
               backgroundImage: {
                  xs: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Figures/fondo-seccion+de+VALORES+MOVIL.jpg)",
                  md: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Figures/Fondo-seccion+DE+VALORES+.jpg)",
               },
               backgroundSize: "cover",
               backgroundPosition: { xs: "center center", md: "center top" },
               display: "flex",
               flexDirection: "column",
               paddingTop: 12,
            }}
         >
            <Box sx={{ display: "flex", justifyContent: "center", pb: 6 }}>
               <Typography sx={{ color: "white", fontSize: { xs: "30px", md: "40px" } }}>
                  Nuestros <strong>valores</strong>
               </Typography>
            </Box>
            <Grid display="flex" sx={{ justifyContent: "center", pb: 9 }} container>
               {principles.map((page) => (
                  <Grid item key={page.key} xs={6} sm={4}>
                     <ModuleCategoryCard
                        description={page.name}
                        mainImg={page.img}
                        darkbg={true}
                        subDescription={page.description}
                     />
                  </Grid>
               ))}
            </Grid>
         </Box>
         <Grid container sx={{ py: { xs: 5, md: 15 }, px: { md: 10 }, display: "flex", alignItems: "center" }}>
            <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "center" }}>
               <Typography
                  color={"primary"}
                  sx={{ lineHeight: 1, fontSize: { xs: "30px", md: "40px" }, mb: { xs: 5, sm: 4, md: 0 } }}
               >
                  Nuestro <strong>objetivo</strong>
               </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
               <Box
                  sx={{
                     bgcolor: "rgba(217,217,217,0.2)",
                     boxShadow: 3,
                     mx: { xs: 0, md: 5, lg: 10 },
                     p: { xs: 4, sm: 10, md: 8 },
                     borderRadius: { md: "10px" },
                  }}
               >
                  <Typography textAlign={"justify"} lineHeight={1.3}>
                     Ofrecer soluciones confiables, sencillas y seguras que le otorgan certeza a la continuidad de su
                     organización, su patrimonio y una operatividad orientada a las buenas prácticas empresariales
                     mediante el uso de la tecnología.
                  </Typography>
               </Box>
            </Grid>
         </Grid>
         {ScrollToTopButton(window)}
         <Footer />
      </ThemeConfig>
   );
};
