export const modulesInfo = [
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Canal+de+denuncia.svg)",
      title: "Canal de Denuncias",
      description:
         "Implemente el medio de comunicación para identificar riesgos de manera temprana y fortalecer la imagen de su empresa.",
      link: "/canal-denuncias",
      avaliable: true,
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Gobierno+Corporativo+copia.svg)",
      title: "Gobierno Corporativo",
      description:
         "Digitalice su Gobierno Corporativo con sencillez, obteniendo como resultado la transparencia y control hasta en los pequeños detalles.",
      link: "/gobierno-corporativo",
      avaliable: true,
   },
   
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Beneficiario+Controlador-08.svg)",
      title: "Beneficiario Controlador",
      description:
         "Simplifique la gestión de Beneficiarios Controladores con mecanismos eficientes, reduciendo el riesgo de sanciones.",
      link: "/beneficiario-controlador",
      avaliable: true,
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/ICONO+modulo+LECOSY_REPSE.svg)",
      title: "REPSE",
      description:
         "Gestione todas las obligaciones del REPSE, asegurando la materialidad necesaria en cada servicio especializado.",
      link: "/repse",
      avaliable: true,
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/ICONOS+de+m%C3%B3dulos+LECOSY_REPSE+%26+SIROC-09+1.svg)",
      title: "SIROC",
      description:
         "Gestione el cumplimiento de las obligaciones del SIROC, permitiendo mantener un seguimiento eficiente a cada obra.",
      link: "/siroc",
      avaliable: true,
   },
   
   
];

export const modulesInfoTwo = [
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Programa+de+cumplimiento.svg)",
      title: "Programa de Cumplimiento",
      description:
         "Asegure y optimice el debido cumplimiento de sus obligaciones a través de la automatización de actividades de gestión.",
      link: "/soluciones#1",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Gesti%C3%B3n+de+riesgos.svg)",
      title: "Gestión de Riesgos",
      description: "Identifique, controle y gestione los riesgos que pueden afectar el desempeño de sus objetivos.",
      link: "/soluciones#2",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Auditor%C3%ADa+interna+.svg)",
      title: "Auditoría Interna",
      description: "Realice evaluaciones exhaustivas que permitan asegurar el cumplimiento y buenas prácticas.",
      link: "/soluciones#4",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Responsabilidad+Corporativa+copia.svg)",
      title: "Responsabilidad Corporativa",
      description:
         "Potencie la sostenibilidad e impacto positivo de su organización, actuando bajo guías de integridad y buenas prácticas.",
      link: "/soluciones#5",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Correlaci%C3%B3n+de+leyes.svg)",
      title: "Correlación de Leyes",
      description:
         "Realice análisis, comparaciones y vincule distintas legislaciones a través de nuestra sólida base jurídica.",
      link: "/soluciones#3",
   },
   
];
