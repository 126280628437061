import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Button, Stack, Divider } from "@mui/material";
import { Footer, NavBar } from "../components";
import { ThemeConfig } from "../config/theme.config";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ScrollToTop from "../wrapper/scrollToTop";
import "./gradientBoxes.css";
import { QuestionAccordion } from "../components/questionAccordion";
import { ScrollToTopButton } from "../components/scrollToTopButton";
import { DenounceQuestions } from "../const/AccordionQuestions";
import VideoContainer from "../components/VideoContainer";
import { descriptionZone, imageZone } from "./ServicesScreen";
import ServiceTitleSection from "../components/ServiceTitleSection";
import { StyledAccordionComponent } from "../components/StyledAccordion/styledAccordionComponent";
import { DenounceChannelContent } from "../const/StyledAccordionContent";
import { DenounceBenefits } from "../const/ServicesBenefits";
import WhatIsServiceComponent from "../components/WhatIsServiceComponent";
import ServiceImportanceSection from "../components/ServiceImportanceSection";
import { useState, useRef, useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const facilities = (description, bold) => {
   return (
      <Box sx={{ display: "flex", py: 0.5, alignItems: "center" }}>
         <CheckCircleIcon
            sx={{ borderRadius: "50%", bgcolor: "#D9D9D9", color: bold ? "#162c44" : "#8A95A1", mx: 1 }}
         />
         <Typography fontWeight={500} sx={{fontSize:{xs:'12px',lg:'0.8rem'}}} variant="subtitle2">
            {description}
         </Typography>
      </Box>
   );
};
const facilitiesGray = (description, bold) => {
   return (
      <Box sx={{ display: "flex", py: 0.5, alignItems: "center" }}>
         <CheckCircleIcon
            sx={{ borderRadius: "50%", bgcolor: "#5C6C7C", color: bold ? "#D9D9D9" : "#FFFFFF", mx: 1 }}
         />
         <Typography fontFamily={"regular"} sx={{fontSize:{xs:'12px',lg:'0.8rem'}}}  variant="subtitle2">
            {description}
         </Typography>
      </Box>
   );
};

const sliderSettings = {
   autoplay: true,
   speed: 1000,
   autoplaySpeed: 5000,
   infinite: true,
   slidesToShow: 1,
   slidesToScroll: 1,
   arrows: false,
};

export const DenounceScreen = () => {
   const playerRef = useRef(null);

   const styledAccContent = {
      details: (
         <Typography textAlign={"justify"} key={"mainTitleContent"}>
            A través de nuestra plataforma personalizada con la identidad corporativa de su organización, la persona
            interesada interpondrá su denuncia, queja o sugerencia desde cualquier dispositivo y desde cualquier lugar.
            <br />
            <br />
            Además, es posible realizar, en tiempo real, el seguimiento de las denuncias, quejas o sugerencias,
            ingresando el número de reporte y el código de verificación que le es proporcionado al usuario.
         </Typography>
      ),
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/videos/1.+Interposici%C3%B3n+de+denuncia%2C+queja+o+sugerencia.mp4",
   };
   const navigate = useNavigate();
   const [selectedText, setSelectedText] = useState(styledAccContent.details);
   const [videoContainerUrl, setVideoContainerUrl] = useState(styledAccContent.videoUrl);

   const facilitiesList = [
      "Rápida implementación",
      "Fácil acceso y uso",
      "Diseño personalizado",
      "Capacitación personalizada",
      "Informes y seguimiento",
      "Herramientas de mkt digital",
      "Soporte técnico",
      "Sistema en la nube",
   ];
   const facilitiesSecondaryList = [
      "Proceso de gestión",
      "Clasificación",
      "Análisis",
      "Proceso de investigación",
      "Recomendaciones",
      "Resolución",
   ];

   const facilitiesGrid = (intern) => {
      return (
         <Grid container>
            <Grid item xs={6} >
               {facilitiesList.map((e) => {
                  return facilities(e);
               })}
            </Grid>
            <Grid
               item
               xs={6}
               sx={{
                  display: "flex",
                  height: {
                     xs: intern ? "95px" : "260px",
                     md: intern ? "80px" : "250px",
                  },
               }}
            >
               <Box
                  width={"3px"}
                  sx={{
                     mt: 2,
                     mb: { xs: 10, md: 8 },
                     bgcolor: "#162c44",
                     position: "relative",
                     left: "21px",
                  }}
               />
               <Box zIndex={2}>
                  {facilitiesSecondaryList.map((e, i) => {
                     if (i === 0) return facilities(e, true);
                     if (intern && i > 1) return undefined;
                     return facilities(e);
                  })}
               </Box>
            </Grid>
         </Grid>
      );
   };
   const facilitiesGridGray = (intern) => {
      return (
         <Grid container>
            <Grid item xs={6}>
               {facilitiesList.map((e) => {
                  return facilitiesGray(e);
               })}
            </Grid>
            <Grid
               item
               xs={6}
               sx={{
                  display: "flex",
                  height: {
                     xs: intern ? "95px" : "260px",
                     md: intern ? "80px" : "250px",
                  },
               }}
            >
               <Box
                  width={"3px"}
                  sx={{
                     mt: 3,
                     mb: { xs: 6, md: 3 },
                     bgcolor: "#D9D9D9",
                     position: "relative",
                     left: "21px",
                  }}
               />
               <Box zIndex={2}>
                  {facilitiesSecondaryList.map((e, i) => {
                     if (i === 0) return facilitiesGray(e, true);
                     if (intern && i > 1) return undefined;
                     return facilitiesGray(e);
                  })}
               </Box>
            </Grid>
         </Grid>
      );
   };
   return (
      <ThemeConfig>
         <ScrollToTop>
            <Stack direction={"column"}>
               <NavBar />
               <Box position={"relative"} sx={{ mt: -14 }} pb={{ md: 5, lg: 0 }} mb={{ md: 0, lg: 6.5 }}>
                  <Box position={"absolute"} top={0} width={"100%"} zIndex={-1}>
                     <Slider centerMode={false} {...sliderSettings} adaptiveHeight>
                        <Box
                           sx={{
                              backgroundImage:
                                 "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/fondos/CD-Banner.png)",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              width: "100%",
                              height: { xs: "450px", sm: "65vh", md: "615px", lg: "750px" },
                           }}
                        />
                        <Box
                           sx={{
                              backgroundImage:
                                 "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/fondos/CD-Banner-2.png)",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              width: "100%",
                              height: { xs: "450px", sm: "65vh", md: "615px", lg: "750px" },
                           }}
                        />
                        <Box
                           sx={{
                              backgroundImage:
                                 "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/fondos/CD-Banner-3.png)",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              width: "100%",
                              height: { xs: "450px", sm: "65vh", md: "615px", lg: "750px" },
                           }}
                        />
                     </Slider>
                  </Box>
                  <Box sx={{ mt: "110px" }}>
                     <ServiceTitleSection
                        title={"Canal de Denuncias"}
                        phrase={"Un símbolo de transparencia y mejora continua"}
                        icon={
                           "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Canal+de+denuncia.svg)"
                        }
                     />
                  </Box>
               </Box>

               <Box my={{ xs: 10, sm: 0, md: -5, lg: -11 }} position={"relative"} bgcolor={"transparent"}>
                  {/* <Box
                     mt={{ lg: -5 }}
                     px={{ xs: 4, lg: 10 }}
                     pt={{ lg: 10 }}
                     sx={{
                        backgroundImage: {
                           lg: `url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/curvedBg.png)`,
                        },
                        backgroundSize: { sm: "0% 0%", md: "100% 135%" },
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                     }}
                  >
                     <VideoContainer
                        videoUrl={
                           "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/videos/Escaneando+codigo+QR+del+CANAL+DE+DENUNCIAS.mp4"
                        }
                        direction="row-reverse"
                        alternComponent={
                           <Typography sx={{ color: "#162c44", fontSize: { md: "18px" } }} py={{ sm: 0, md: 12 }}>
                              En el camino hacia la mejora continua, el Canal de Denuncias{" "}
                              <strong>
                                 es el primer paso para fomentar una cultura empresarial sólida y responsable.
                              </strong>
                           </Typography>
                        }
                        videoPlayerProps={{
                           height: "100%",
                           width: { xs: "100%", lg: "75%" },
                           alignSelf: "center",
                        }}
                     />
                  </Box> */}
                  <Box
                     mt={{ lg: -5 }}
                     px={{ xs: 4, lg: 10 }}
                     pt={{ lg: 10 }}
                     sx={{
                        backgroundImage: {
                           lg: `url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/curvedBg.png)`,
                        },
                        backgroundSize: { sm: "0% 0%", md: "100% 135%" },
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                     }}
                  >
                     <Grid container spacing={{ md: 15 }} px={{ xs: 0, sm: 6, md: 10 }} py={{ xs: 4, sm: 10, md: 15 }}>
                        <Grid
                           item
                           sm={12}
                           md={6}
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                              pt: "0px !important",
                              pl: { xs: 0, sm: "40px !important" },
                           }}
                        >
                           <Typography sx={{ color: "#162c44", fontSize: { md: "18px" } }}>
                              En el camino hacia la mejora continua, el Canal de Denuncias{" "}
                              <strong>
                                 es el primer paso para fomentar una cultura empresarial sólida y responsable.
                              </strong>
                           </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} sx={{ py: { xs: 4, sm: 4, md: 8, lg: 11 } }}>
                           <Box
                              sx={{
                                 display: "flex",
                                 mt: { md: "-20px", lg: "-20px" },

                                 justifyContent: "center",
                              }}
                           >
                              <ReactPlayer
                                 className="player"
                                 playing={true}
                                 muted
                                 url={
                                    "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/videos/Escaneando+codigo+QR+del+CANAL+DE+DENUNCIAS.mp4"
                                 }
                                 loop
                                 width={"auto"}
                                 height={"230px"}
                                 style={{ height: "100%", width: { xs: "100%", lg: "75%" }, alignSelf: "center" }}
                              />
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Box>

               <Stack
                  direction={{ xs: "column-reverse", lg: "row-reverse" }}
                  sx={{ bgcolor: "#748190", display: "flex", minHeight: "600px", height: "100%" }}
               >
                  <Stack direction={"column"} flex={1}>
                     <Box
                        flex={1}
                        pb={10}
                        mt={10}
                        px={{ xs: 2, lg: 10 }}
                        bgcolor={"#748190"}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                     >
                        <Box
                           display={"flex"}
                           flexDirection={"column"}
                           justifyContent={"center"}
                           py={"5%"}
                           sx={{ px: { xs: 2, sm: 7, md: 19, lg: 1 } }}
                        >
                           <Typography
                              fontFamily={"bold"}
                              color={"white"}
                              sx={{ fontSize: { xs: "30px", md: "40px" }, textAlign: { xs: "center", lg: "left" } }}
                           >
                              ¿Qué es?
                           </Typography>
                           <Typography color={"white"} textAlign={"justify"}>
                              Es una herramienta para que los colaboradores, clientes o cualquier otra persona pueda
                              informar de manera confidencial y anónima sobre cualquier actividad ilegal, inapropiada o
                              no ética que ocurra dentro de la organización, o bien, brindar una sugerencia que pueda
                              contribuir al éxito.
                           </Typography>

                           <div>
                              <Typography
                                 fontFamily={"bold"}
                                 color={"white"}
                                 sx={{
                                    py: 2,
                                    mt: 2,
                                    fontSize: { xs: "30px", md: "40px" },
                                    textAlign: { xs: "center", md: "left" },
                                    lineHeight: 1.3,
                                 }}
                              >
                                 ¿Por qué contar con él?
                              </Typography>
                              <Typography color={"white"} textAlign={"justify"}>
                                 A través de esta herramienta es posible detectar y prevenir actividades indebidas antes
                                 de que se conviertan en problemas mayores que puedan afectar la integridad de las
                                 organizaciones. Además, su implementación es una herramienta efectiva para fomentar una
                                 cultura de transparencia y honestidad dentro de una organización.
                              </Typography>
                           </div>
                        </Box>
                        <Box flex={1}>
                           <Box
                              flex={1}
                              height={"100%"}
                              px={{ xs: 3, lg: 10 }}
                              display={"flex"}
                              flexDirection={"column"}
                              justifyContent={"center"}
                           >
                              <Box
                                 width={{ xs: "100%", sm: "100%", md: "50%", lg: "20%" }}
                                 height={{ xs: "300px", sm: "300", md: "300px", lg: "350px" }}
                                 position={"absolute"}
                                 right={"0%"}
                                 sx={{
                                    backgroundImage:
                                       "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/Fondo-Seccion-3-derecho.png)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: { xs: "300px 300px", lg: "350px 350px" },
                                    backgroundPosition: "bottom right",
                                    opacity: 0.15,
                                 }}
                              ></Box>
                           </Box>
                        </Box>
                     </Box>
                  </Stack>
                  <Box flex={1}>
                     <Box height={"100%"} px={0} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                        <Box
                           width={{ xs: "100%", lg: "20%" }}
                           height={{ xs: "600px", md: "400px", lg: "675px" }}
                           position={"absolute"}
                           right={{ xs: "0", lg: "50%" }}
                        />

                        <Box
                           sx={{
                              height: { xs: "100vw", lg: "100%" },
                              maxHeight: { sm: "400px", md: "500px", lg: "100%" },

                              backgroundImage:
                                 "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/fondos/8+1.png)",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "70% 20%",
                           }}
                        />
                     </Box>
                  </Box>
               </Stack>

               <VideoContainer
                  display="block"
                  direction="row-reverse"
                  title={
                     <Typography
                        fontFamily={"bold"}
                        pl={{ xs: "20px", md: "85px" }}
                        fontSize={{ xs: "30px", lg: "35px" }}
                        lineHeight={1.2}
                        sx={{ borderBottom: 5, borderColor: "#8A95A1" }}
                     >
                        ¿Cómo funciona el{" "}
                        <Typography fontFamily={"ExtraBold"} fontSize={{ xs: "30px", lg: "35px" }} lineHeight={1.1}>
                           Módulo de Canal de Denuncias?
                        </Typography>
                     </Typography>
                  }
                  alternComponent={
                     <StyledAccordionComponent
                        title="Interposición de denuncia, queja o sugerencia"
                        content={DenounceChannelContent}
                        selectedAnswer={selectedText}
                        setSelectedAnswer={setSelectedText}
                        setSelectedVideo={setVideoContainerUrl}
                        mainTitleContent={styledAccContent.details}
                        mainVideoContent={styledAccContent.videoUrl}
                     />
                  }
                  videoUrl={videoContainerUrl}
                  videoPlayerProps={{ width: { lg: "80%" }, mx: "10%" }}
                  subComponent={
                     <Stack direction={"column"} spacing={2} px={{ xs: 1, md: 2 }} mx={"5%"}>
                        <Typography>{selectedText}</Typography>
                     </Stack>
                  }
               />
               <ServiceImportanceSection
                  title={
                     <Typography
                        fontFamily={"bold"}
                        fontSize={{ xs: "28px", md: "35px" }}
                        maxWidth={{ sm: "100%", md: "360px" }}
                        sx={{
                           lineHeight: 1.3,
                           textAlign: { xs: "center", sm: "center", md: "left" },
                        }}
                     >
                        ¿Debo tener un <strong style={{ fontFamily: "extraBold" }}>Canal de Denuncias</strong> en mi
                        organización?
                     </Typography>
                  }
                  description={
                     <Stack direction={"column"} spacing={2} flex={1} sx={{ px: { xs: 2 } }}>
                        <Typography textAlign={"justify"}>
                           La legislación mexicana establece que las empresas pueden ser penalmente responsables de los
                           delitos cometidos en su nombre si no cuentan con el debido control en su organización. La Ley
                           General de Responsabilidades Administrativas establece que se valorará si la empresa cuenta
                           con políticas de integridad y sistemas adecuados de denuncia. Además, contar con herramientas
                           de denuncia puede ser considerado como una atenuante en la imposición de sanciones a la
                           empresa.
                        </Typography>
                        <Typography textAlign={"justify"}>
                           Por lo tanto, tener una herramienta de denuncia en la organización puede protegerla legal y
                           reputacionalmente, además de ser una buena práctica y un requisito en algunas normas
                           oficiales mexicanas (NOM-035-STPS factores de riesgo psicosocial en el trabajo, NOM-247-SE
                           prácticas comerciales...).
                        </Typography>
                     </Stack>
                  }
                  phrase={
                     <Typography
                        fontSize={{ xs: "20px", md: "25px" }}
                        textAlign={"center"}
                        pt={{ xs: 2, lg: 8 }}
                        px={{ lg: 30 }}
                        lineHeight={1.2}
                     >
                        Ofrecemos a las empresas la posibilidad de{" "}
                        <strong>prevenir, mitigar o erradicar riesgos</strong> mediante una&nbsp;{""}
                        <strong>herramienta de gestión</strong> que proporcione los elementos necesarios para ello.
                     </Typography>
                  }
                  additionalComponent={
                     <Box display={"flex"} justifyContent={"center"} p={{ xs: 4, lg: 5 }}>
                        <Button
                           sx={{ bgcolor: "white", "&:hover": { bgcolor: "#8A95A1" }, px: 2, py: 1 }}
                           onClick={() => {
                              const element = document.getElementById("plans");
                              element.scrollIntoView({
                                 behavior: "smooth",
                                 block: "center",
                                 inline: "center",
                              });
                           }}
                        >
                           <Typography
                              color={"#162c44"}
                              fontFamily={"bold"}
                              sx={{ lineHeight: 1, fontSize: "0.875rem" }}
                           >
                              Quiero un Canal de Denuncias para mi empresa
                           </Typography>
                        </Button>
                     </Box>
                  }
               />
               <Stack
                  direction={"column"}
                  spacing={3}
                  p={{ xs: 3, lg: 10 }}
                  sx={{
                     backgroundImage:
                        "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Fondo+beneficios.png)",
                     backgroundPosition: "center top",
                     backgroundSize: "cover",
                     backgroundRepeat: "no-repeat",
                  }}
               >
                  <Box display={"flex"} justifyContent={"center"} px={{ xs: 1, sm: 4, lg: "auto" }}>
                     <Typography
                        textAlign={"center"}
                        color={"#162c44"}
                        fontFamily={"bold"}
                        fontSize={{ xs: "1.4rem", sm: "1.5rem", lg: "2rem" }}
                        sx={{ borderBottom: 5, lineHeight: { xs: 1.2, sm: 1.4 }, pt: 6, mx: 1, pb: { xs: 1 } }}
                     >
                        Beneficios que logrará con la <br />
                        implementación de nuestro Canal de Denuncias
                     </Typography>
                  </Box>
                  <Grid container>
                     {DenounceBenefits.map((benefit, i) => {
                        return (
                           <Grid
                              item
                              md={12}
                              sx={{
                                 display: "flex",
                                 lignItems: "center",
                                 justifyContent: "center",
                                 alingSelf: "center",
                              }}
                           >
                              <Box
                                 columnGap={{ sm: 0, md: 4, lg: 0 }}
                                 id={`section${i + 1}`}
                                 key={benefit.title}
                                 sx={{
                                    display: "flex",
                                    flexDirection: {
                                       xs: "column",
                                       md: i % 2 === 0 ? "row" : "row-reverse",
                                    },
                                    alignItems: { md: "center" },
                                    py: { xs: 4, sm: 2, md: 0 },
                                    px: { xs: 1, sm: 4 },
                                    maxWidth: { md: "1000px" },
                                    minWidth: { lg: "1240px" },
                                    textAlign: "justify",
                                 }}
                              >
                                 {descriptionZone(benefit, i)}
                                 {imageZone(benefit, i % 2 !== 0)}
                              </Box>
                           </Grid>
                        );
                     })}
                  </Grid>
               </Stack>
               <Box className="gradientBox902" sx={{ px: { xs: 2, md: 1, lg: 15 }, py: { xs: 3, md: 5 } }} id={"plans"}>
                  <AnimationOnScroll
                     animateIn="animate__zoomIn  animate__fadeIn"
                     duration={0.75}
                     animateOnce
                     animatePreScroll={false}
                     style={{ display: "flex", justifyContent: "center" }}
                  >
                     <Typography
                        color={"white"}
                        textAlign={"center"}
                        sx={{
                           pt: 5,
                           fontSize: { xs: "30px", md: "40px" },
                           lineHeight: 1.2,
                           maxWidth: "700px",
                        }}
                     >
                        <strong>Elija el plan</strong> que mejor <strong>se ajuste a sus necesidades.</strong>
                     </Typography>
                  </AnimationOnScroll>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "space-center" },
                        flexDirection: { xs: "column", md: "row" },
                        pt: 6,
                        gap: {md:1,lg:4},
                     }}
                  >
                     <AnimationOnScroll animateIn="animate__slideInUp" duration={0.75} animatePreScroll animateOnce>
                        <Box display={"grid"} sx={{ justifyContent: "center" }}>
                           
                           <Box
                              gridRow={"5/10"}
                              gridColumn={"1/4"}
                              sx={{
                                 bgcolor: "#5C6C7C",

                                 borderRadius: 2,
                                 display: "flex",
                                 flexDirection: "column",
                                 justifyContent: "center",
                                 maxWidth: "490px",
                                 minHeight: "500px",
                                 pt: '45px',
                                 px: "15px",
                                 marginTop: "38px",
                              }}
                           >
                              <Typography
                                 textAlign={"center"}
                                 color={"#FFFFFF"}
                                 fontFamily={"bold"}
                                 sx={{
                                    lineHeight: 1,
                                    fontSize: { xs: "30px", md: "40px" },
                                 }}
                              >
                                 Gestión interna
                              </Typography>

                              <Box sx={{ minHeight: "104px" }}>
                                 <Typography
                                    textAlign={"center"}
                                    sx={{ lineHeight: 1.4, py: 1, px: { xs: 2, sm: 2, md: 2 }, color: "#FFFFFF", fontSize:{xs:'0.95rem', sm:'0.9rem'} }}
                                 >
                                    Dirigida a organizaciones que cuenten con la estructura necesaria para autogestionar
                                    sus denuncias, quejas o sugerencias.
                                 </Typography>
                              </Box>
                              <hr style={{ width: "95%", borderColor: "#D9D9D9", borderStyle: "solid" }}></hr>
                              <Box sx={{ color: "white" }}>{facilitiesGridGray(true)}</Box>
                              <Button
                                 style={{ border: "1px solid rgb(255, 255, 255)" }}
                                 onClick={() => navigate("/contacto")}
                                 onMouseEnter={{ bgcolor: "#FFFFFF" }}
                                 sx={{
                                    borderRadius: 2,
                                    px: 2,
                                    alignSelf: "center",
                                    my: 2,
                                    mt: 2,
                                    bgcolor: "#5C6C7C",
                                 }}
                              >
                                 INVERTIR AHORA
                              </Button>
                           </Box>
                        </Box>
                     </AnimationOnScroll>
                     <AnimationOnScroll
                        animateIn="animate__slideInUp"
                        duration={0.75}
                        delay={1200}
                        animatePreScroll
                        animateOnce
                     >
                        <Box display={"grid"} sx={{ justifyContent: "center", pt: { xs: 5, md: 0 } }}>
                           <Typography
                              fontFamily={"bold"}
                              textAlign={"center"}
                              color={"primary"}
                              variant="h6"
                              gridRow={"4/6"}
                              gridColumn={"2/3"}
                              zIndex={2}
                              sx={{ borderRadius: '15px', bgcolor: "white", p: "10px 30px", marginBottom:'25px' }}
                           >
                              Recomendado
                           </Typography>
                           <Box
                              gridRow={"5/10"}
                              gridColumn={"1/4"}
                              sx={{
                                 bgcolor: "#D9D9D9",

                                 borderRadius: 2,
                                 display: "flex",
                                 flexDirection: "column",
                                 justifyContent: "center",
                                 maxWidth: "490px",
                                 minHeight: "500px",
                                 pt: '45px',
                                 px: "15px",
                              }}
                           >
                              <Typography
                                 textAlign={"center"}
                                 color={"#162c44"}
                                 fontFamily={"bold"}
                                 sx={{
                                    lineHeight: 1,
                                    fontSize: { xs: "30px", md: "40px" },
                                 }}
                              >
                                 Gestión Lecosy
                              </Typography>

                              <Box sx={{ minHeight: "80px" }}>
                                 <Typography
                                    textAlign={"center"}
                                    sx={{ lineHeight: 1.4, py: 1.45, px: { xs: 2, sm: 2, md: 2 } , fontSize:{xs:'0.95rem', sm:'0.9rem'}}}
                                 >
                                    Ideal para organizaciones de cualquier tipo y tamaño que necesitan un equipo
                                    especializado que se encargue de gestionar sus denuncias, quejas o sugerencias de
                                    manera profesional y eficiente.
                                 </Typography>
                              </Box>
                              <hr style={{ width: "95%", borderColor: "#5C6C7C", borderStyle: "solid" }}></hr>
                              <Box sx={{ paddingX: 1 }}>{facilitiesGrid(false)}</Box>
                              <Button
                                 onClick={() => navigate("/contacto")}
                                 sx={{
                                    borderRadius: 2,
                                    px: 2,
                                    alignSelf: "center",
                                    my: 2,
                                    mt: 2,
                                    bgcolor: "#162c44",
                                    "&:hover": { bgcolor: "#5C6C7C" },
                                 }}
                              >
                                 INVERTIR AHORA
                              </Button>
                           </Box>
                        </Box>
                     </AnimationOnScroll>
                  </Box>
                  <AnimationOnScroll
                     animateIn="animate__zoomIn  animate__fadeIn"
                     duration={0.75}
                     animateOnce
                     animatePreScroll={false}
                     style={{ display: "flex", justifyContent: "center" }}
                  >
                     <Typography
                        color={"white"}
                        textAlign={"center"}
                        sx={{
                           py: 3,
                           fontSize: { xs: "11px", sm: "0.8rem",md:"0.98rem" },
                           lineHeight: 1,
                           maxWidth: "700px",
                        }}
                     >
                        Costo único de implementación $1,500.00
                     </Typography>
                  </AnimationOnScroll>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "space-center" },
                        flexDirection: "column",
                     }}
                  >
                     <AnimationOnScroll animateIn="animate__slideInUp" duration={0.75} animatePreScroll animateOnce>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                           <TableContainer
                              style={{
                                 border: "1px solid rgb(255, 255, 255)",
                                 borderRadius: "15px",
                                 borderCollapse: "none",
                                 maxWidth: "1020px",
                              }}
                              sx={{ padding: { xs: "15px 0px", sm: "15px 15px", md: "15px 70px" } }}
                           >
                              <Table>
                                 <TableHead>
                                    <TableRow>
                                       <TableCell style={{ color: "white" }} sx={{ color: "white", borderRight: 1 }}>
                                          <Typography sx={{ lineHeight: 1.1, fontSize: { xs: "12px", sm: "1rem", md:"1.2rem" } }}>
                                             <strong>Licencia Mensual</strong>
                                          </Typography>
                                       </TableCell>
                                       <TableCell
                                          style={{ color: "white", textAlign: "center" }}
                                          sx={{ color: "white", borderRight: 1 }}
                                       >
                                          <Typography
                                             sx={{
                                                lineHeight: 1.1,
                                                fontWeight: "600",
                                                fontSize: { xs: "10px", sm: "0.75rem", md:"0.9rem" },
                                             }}
                                          >
                                             1 sucursal
                                          </Typography>
                                       </TableCell>
                                       <TableCell
                                          style={{ color: "white", textAlign: "center" }}
                                          sx={{ color: "white", borderRight: 1 }}
                                       >
                                          <Typography
                                             sx={{
                                                lineHeight: 1.1,
                                                fontWeight: "600",
                                                fontSize: { xs: "10px", sm: "0.75rem", md:"0.9rem" },
                                             }}
                                          >
                                             2 a 10 sucursales
                                          </Typography>
                                       </TableCell>
                                       <TableCell style={{ color: "white", textAlign: "center" }}>
                                          <Typography
                                             sx={{
                                                lineHeight: 1.1,
                                                fontWeight: "600",
                                                fontSize: { xs: "10px", sm: "0.75rem", md:"0.9rem" },
                                             }}
                                          >
                                             11+ sucursales
                                          </Typography>
                                       </TableCell>
                                    </TableRow>
                                 </TableHead>
                                 <TableBody>
                                    <TableRow>
                                       <TableCell style={{ color: "white" }} sx={{ color: "white", borderRight: 1 }}>
                                          <Typography
                                             sx={{
                                                lineHeight: 1.1,

                                                fontSize: { xs: "10px", sm: "0.7rem", md:"0.85rem" },
                                             }}
                                          >
                                             Costo base por uso de plataforma<br></br> $650.00
                                          </Typography>
                                       </TableCell>
                                       <TableCell align="center" sx={{ color: "white", borderRight: 1 }}>
                                          <CheckCircleIcon sx={{ color: "#D9D9D9" }} />
                                       </TableCell>
                                       <TableCell align="center" sx={{ color: "white", borderRight: 1 }}>
                                          <CheckCircleIcon
                                             sx={{
                                                color: "#D9D9D9",
                                             }}
                                          />
                                       </TableCell>
                                       <TableCell align="center">
                                          <CheckCircleIcon
                                             sx={{
                                                color: "#D9D9D9",
                                             }}
                                          />
                                       </TableCell>
                                    </TableRow>
                                    <TableRow>
                                       <TableCell style={{ color: "white" }} sx={{ borderRight: 1, borderBottom: 0 }}>
                                          <Typography
                                             sx={{
                                                lineHeight: 1.1,

                                                fontSize: { xs: "10px", sm: "0.7rem", md:"0.85rem" },
                                             }}
                                          >
                                             Adicional por sucursal
                                          </Typography>
                                       </TableCell>
                                       <TableCell sx={{ color: "white", borderRight: 1, borderBottom: 0 }}>
                                          <Typography
                                             sx={{
                                                lineHeight: 1.1,

                                                fontSize: { xs: "10px", sm: "1.15rem" },
                                             }}
                                          ></Typography>
                                       </TableCell>
                                       <TableCell
                                          style={{ color: "white", textAlign: "center" }}
                                          sx={{ borderRight: 1, borderBottom: 0 }}
                                       >
                                          <Typography
                                             sx={{
                                                lineHeight: 1.1,

                                                fontSize: { xs: "10px", sm: "0.7rem", md:"0.85rem" },
                                             }}
                                          >
                                             <strong>$180.00</strong>
                                             <br></br>
                                             por sucursal
                                          </Typography>
                                       </TableCell>
                                       <TableCell
                                          style={{ color: "white", textAlign: "center" }}
                                          sx={{ borderBottom: 0 }}
                                       >
                                          <Typography
                                             sx={{
                                                lineHeight: 1.1,

                                                fontSize: { xs: "10px", sm: "0.7rem", md:"0.85rem" },
                                             }}
                                          >
                                             <strong>$160.00</strong>
                                             <br></br>
                                             por sucursal
                                          </Typography>
                                       </TableCell>
                                    </TableRow>
                                 </TableBody>
                              </Table>
                           </TableContainer>
                        </Box>
                     </AnimationOnScroll>
                  </Box>
                  <AnimationOnScroll
                     animateIn="animate__zoomIn  animate__fadeIn"
                     duration={0.75}
                     animateOnce
                     animatePreScroll={false}
                     style={{ display: "flex", justifyContent: "center" }}
                  >
                     <Typography
                        color={"white"}
                        textAlign={"center"}
                        sx={{
                           py: 3,
                           fontSize: { xs: "10px", sm: "0.7rem", md:"0.85rem" },
                           lineHeight: 1.2,
                           maxWidth: "1000px",
                        }}
                     >
                        Nota: El costo mensual de la licencia será la suma del costo base y el importe correspondiente
                        al número de sucursales adicionales.
                     </Typography>
                  </AnimationOnScroll>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "space-center" },
                        flexDirection: "column",
                     }}
                  >
                     <AnimationOnScroll animateIn="animate__slideInUp" duration={0.75} animatePreScroll animateOnce>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                           <TableContainer
                              style={{
                                 border: "1px solid rgb(255, 255, 255)",
                                 borderRadius: "15px",
                                 borderCollapse: "none",
                                 maxWidth: "1020px",
                              }}
                              sx={{ padding: { xs: "0px 0px 15px 0px", sm: "15px 20px", md: "15px 70px" } }}
                           >
                              <Table>
                                 <TableHead>
                                    <TableRow>
                                       <TableCell
                                          align="center"
                                          colSpan={4}
                                          style={{ color: "white" }}
                                          sx={{ color: "white" }}
                                       >
                                          <Typography
                                             sx={{
                                                lineHeight: 1.1,
                                                fontSize: { xs: "12px", sm: "1rem", md:"1.2rem" },
                                             }}
                                          >
                                             <strong>Licencia Mensual Gestión Lecosy</strong>
                                          </Typography>
                                       </TableCell>
                                    </TableRow>
                                 </TableHead>
                                 <TableBody>
                                    <TableRow>
                                       <TableCell
                                          style={{ color: "white" }}
                                          sx={{
                                             borderRight: 1,
                                             borderBottom: 0,
                                             width: { xs: "34%", sm: "39%", md: "39%" },
                                          }}
                                       >
                                          <Typography
                                             sx={{
                                                lineHeight: 1.2,

                                                fontSize: { xs: "10px", sm: "0.7rem", md:"0.85rem" },
                                             }}
                                             style={{ maxWidth: "100%" }}
                                          >
                                             <strong>Costo por gestión de denuncias, quejas y sugerencias</strong>
                                             <br></br> $280.00 por hora invertida
                                          </Typography>
                                       </TableCell>
                                       <TableCell
                                          align="center"
                                          sx={{
                                             color: "white",
                                             borderRight: 1,
                                             borderBottom: 0,
                                             width: { xs: "20%", sm: "16%", md: "16%" },
                                          }}
                                       >
                                          <CheckCircleIcon
                                             sx={{
                                                color: "#D9D9D9",
                                             }}
                                          />
                                       </TableCell>
                                       <TableCell
                                          align="center"
                                          sx={{
                                             color: "white",
                                             borderRight: 1,
                                             borderBottom: 0,
                                             width: { xs: "23.8%", sm: "23.7%", md: "23.7%" },
                                          }}
                                       >
                                          <CheckCircleIcon
                                             sx={{
                                                color: "#D9D9D9",
                                             }}
                                          />
                                       </TableCell>
                                       <TableCell align="center" sx={{ color: "white", borderBottom: 0 }}>
                                          <CheckCircleIcon
                                             sx={{
                                                color: "#D9D9D9",
                                             }}
                                          />
                                       </TableCell>
                                    </TableRow>
                                 </TableBody>
                              </Table>
                           </TableContainer>
                        </Box>
                     </AnimationOnScroll>
                  </Box>
                  <AnimationOnScroll
                     animateIn="animate__zoomIn  animate__fadeIn"
                     duration={0.75}
                     animateOnce
                     animatePreScroll={false}
                     style={{ display: "flex", justifyContent: "center" }}
                  >
                     <Typography
                        color={"white"}
                        textAlign={"center"}
                        sx={{
                           py: 3,
                           fontSize: { xs: "10px", sm: "0.7rem", md:"0.85rem" },
                           lineHeight: 1.2,
                           maxWidth: "1000px",
                        }}
                     >
                        Nota: Al cierre de cada mes, se calculará el tiempo dedicado a la gestión de denuncias, quejas y
                        sugerencias de su empresa. Este monto será sumado a la licencia mensual correspondiente.
                     </Typography>
                  </AnimationOnScroll>
                  <AnimationOnScroll
                     animateIn="animate__zoomIn  animate__fadeIn"
                     duration={0.75}
                     animateOnce
                     animatePreScroll={false}
                     style={{ display: "flex", justifyContent: "center" }}
                  >
                     <Typography
                        color={"white"}
                        textAlign={"center"}
                        sx={{
                           pt: "100px",
                           pb: 4,
                           fontSize: { xs: "12px", md: "14px" },
                           lineHeight: 1.3,
                           maxWidth: "1000px",
                        }}
                     >
                        *Los precios no incluyen IVA. <br></br>Los precios están expresados en pesos mexicanos.{" "}
                        <br></br>Solicite más información enviando correo a{" "}
                        <a href="mailto:contacto@lecosy.com.mx" style={{ color: "white" }}>
                           contacto@lecosy.com.mx
                        </a>
                     </Typography>
                  </AnimationOnScroll>
               </Box>
               <Box
                  sx={{
                     backgroundImage:
                        "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Figures/Figura-5.png)",
                     backgroundSize: { xs: "0% 0%", md: "55% 100%" },
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "bottom right",
                  }}
               >
                  <Box
                     height={{ lg: "600px" }}
                     width={"400px"}
                     position={"absolute"}
                     right={0}
                     my={"2%"}
                     sx={{
                        backgroundImage:
                           "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Capa_1.png)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                     }}
                  />
                  <Typography
                     fontFamily={"bold"}
                     color={"primary"}
                     sx={{
                        px: { xs: 2, md: 10 },
                        pt: 5,
                        fontSize: { xs: "30px", md: "40px" },
                     }}
                  >
                     Preguntas frecuentes
                  </Typography>
                  {QuestionAccordion(DenounceQuestions, true)}
               </Box>
               {ScrollToTopButton(window)}
               <Footer />
            </Stack>
         </ScrollToTop>
      </ThemeConfig>
   );
};
