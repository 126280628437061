import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

export const ModuleCategoryCard = ({ description, mainImg, darkbg = false, useIcons = false, subDescription }) => {
   const [iconScale, setIconScale] = React.useState(1); 
   const maxScroll = document.documentElement.scrollHeight - window.innerHeight; 

   React.useEffect(() => {
      const handleScroll = () => {
         let scrollY = window.scrollY;
         let scale = 0.5 + (scrollY / maxScroll) * (1.2 - 0.5); 

         if (scale > 1.2) scale = 1.2;
         if (scale < 0.5) scale = 0.5;

         setIconScale(scale);
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
   }, []);

   return (
      <Card
         sx={{
            display: "flex",
            justifyContent: "center",
            border: "none",
            boxShadow: "none",
            backgroundColor: "transparent",
            position: "relative",
         }}
      >
         <CardContent>
            {!useIcons ? (
               <Box
                  component="img"
                  src={mainImg}
                  alt={description}
                  sx={{
                     display: "flex",
                     width: "100%",
                     height: "4rem",
                     justifyContent: "center",
                     mb: 1,
                     transform: `scale(${iconScale})`, 
                     transition: "transform 0.2s ease-out", 
                  }}
               />
            ) : (
               <Box sx={{ display: "flex", justifyContent: "center", color: "white" }}>{mainImg}</Box>
            )}
            <Typography
               gutterBottom
               component="div"
               textAlign="center"
               fontFamily="bold"
               sx={{ color: darkbg ? "white" : "black" }}
            >
               {description}
            </Typography>
            <Typography textAlign="center" sx={{ px: { md: 10 }, color: darkbg ? "white" : "black" }}>
               {subDescription}
            </Typography>
         </CardContent>
      </Card>
   );
};

ModuleCategoryCard.propTypes = {
   description: PropTypes.string.isRequired,
   mainImg: PropTypes.string.isRequired,
   darkbg: PropTypes.bool,
   useIcons: PropTypes.bool.isRequired,
   subDescription: PropTypes.string.isRequired,
};
