import { ThemeConfig } from "../config/theme.config";
import ScrollToTop from "../wrapper/scrollToTop";
import { Box, Collapse, Stack, Typography, Grid, IconButton, Link, Button } from "@mui/material";
import { Footer, NavBar } from "../components";
import ServiceTitleSectionIconFirst from "../components/ServiceTitleSectionIconFirst";
import ServiceScreenVideo, { descriptionZone } from "./ServicesScreenVideo";
import ImageAccContainer from "../components/ImageAccContainer";
import { StyledAccordionComponentImage } from "../components/StyledAccordion/styledAccordionComponentImage";
import { SirocBodyContent } from "../const/StyledAccordionContent";
import { SirocBenefits } from "../const/ServicesBenefits";
import { ScrollToTopButton } from "../components/scrollToTopButton";
import WhatIsServiceComponentSiroc from "../components/WhatIsServiceComponentSiroc";
import { QuestionAccordion } from "../components/questionAccordion";
import { SirocQuestions } from "../const/AccordionQuestions";
import { useState, useRef, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContactConsultantButton from "../components/contactConsultantButton";
import { useNavigate } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ReactPlayer from "react-player";
import PlanInversion from "../components/PlanInversion";

export const Siroc = () => {
   const playerRef = useRef(null);
   const [isPlaying, setIsPlaying] = useState(false);
   const sectionRef = useRef(null);
   useState(false);
   useEffect(() => {
      const observe = new IntersectionObserver(
         ([entry]) => {
            setIsPlaying(entry.isIntersecting);
         },
         {
            root: null, //observa el viewport completo
            threshold: 0.5, //Se activara cuando el 50% del video este en pantalla
         }
      );
      if (playerRef.current) {
         observe.observe(playerRef.current);
      }
      return () => {
         if (playerRef.current) {
            observe.unobserve(playerRef.current);
         }
      };
   }, []);
   const handleScrollToSection = () => {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
   };

   const styledAccContent = {
      subTitleR: (
         <Typography
            key={"mainSubTitleRContent"}
            fontFamily={"ExtraBold"}
            fontSize={"18px"}
            color={"#162c44"}
            textAlign={"left"}
         >
            Cumplimiento
         </Typography>
      ),
      details: (
         <Typography textAlign={"left"} key={"mainTitleContent"}>
            Obtenga una guía de cumplimiento que le brinde una visión integral de todas las obligaciones derivadas del
            SIROC, permitiéndole comprender y abordar proactivamente sus responsabilidades en la prevención de riesgos.
         </Typography>
      ),
      imageUrl: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Guia+de+cumplimiento+1.png",
   };

   const [imageAccContainerUrl, setImageAccContainerUrl] = useState(styledAccContent.imageUrl);
   const [selectedText, setSelectedText] = useState(styledAccContent.details);
   const [selectedSubTitleR, setSelectedSubTitleR] = useState(styledAccContent.subTitleR);

   //cambios
   const [showContent, setShowContent] = useState(false);
   const toggleContent = () => {
      setShowContent(!showContent);
   };
   const navigate = useNavigate();
   const [showsExtraContent, setShowsExtraContent] = useState(false);

   return (
      <ThemeConfig>
         <ScrollToTop>
            <NavBar />
            <Box position={"relative"} sx={{ mt: -14, pt: 18 }}>
               <Box position={"absolute"} top={0} width={"100%"} zIndex={-1}>
                  <Box
                     sx={{
                        backgroundImage:
                           "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/50+1.png)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: { xs: "450px", md: "615px", lg: "700px" },
                     }}
                  />
               </Box>

               <ServiceTitleSectionIconFirst
                  icon={
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/ICONOS+de+m%C3%B3dulos+LECOSY_REPSE+%26+SIROC-09+1.svg)"
                  }
                  title={"SIROC"}
                  phrase={
                     <Typography color={"primary"} sx={{ fontSize: { xs: "15px", md: "20px" }, lineHeight: 1.2 }}>
                        Simplifique el cumplimiento de las obligaciones en el SIROC y optimice la gestión de las obras.
                     </Typography>
                  }
               />
            </Box>

            <Box
               mt={{ md: 0, lg: -10 }}
               position={"relative"}
               bgcolor={"transparent"}
               zIndex={2}
               sx={{
                  backgroundImage: `url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/curvedBg.png)`,
                  backgroundSize: { md: "0% 0%", lg: "100% 135%" },
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  px: { sm: 4, md: 0 },
                  pb: { md: 10 },
                  py: { xs: 4, lg: 4 },
               }}
            >
               <Grid container spacing={{ md: 10 }} px={{ xs: 4, sm: 6, md: 10 }} py={{ xs: 4, sm: 10, md: 6, lg: 10 }}>
                  <Grid
                     item
                     sm={12}
                     md={6}
                     sx={{ display: "flex", alignItems: "center", flexDirection: "column", pb: { md: 0, lg: 4 } }}
                  >
                     <Typography
                        sx={{
                           color: "#162c44",
                           fontSize: { md: "18px" },
                           textAlign: "justify",
                           lineHeight: 1.3,
                        }}
                        pr={{ lg: 6 }}
                     >
                        Las empresas del sector de la construcción están obligadas a registrar cada una de sus obras en
                        el SIROC (Servicio Integral de Registro de Obras de Construcción). Este registro es crucial para
                        cumplir con las obligaciones establecidas por el IMSS (Instituto Mexicano de Seguridad Social), 
                        para garantizar el adecuado seguimiento y control de cada proyecto de construcción.
                     </Typography>
                     <Collapse in={showsExtraContent}>
                        <Typography
                           sx={{
                              color: "#162c44",
                              fontSize: { md: "18px" },
                              textAlign: "justify",
                              lineHeight: 1.3,
                           }}
                           pr={{ lg: 6 }}
                        >
                           <br></br>
                           Se entiende por obras de construcción a cualquier trabajo que tenga por objeto crear,
                           construir, instalar, conservar, reparar, ampliar, demoler o modificar inmuebles, así como la
                           instalación o incorporación en ellos de bienes muebles necesarios para su realización o que
                           se le integren y todos aquellos de naturaleza análoga a los supuestos anteriores.
                        </Typography>
                     </Collapse>
                     <Box sx={{ alignSelf: "flex-start" }}>
                        <Button
                           onClick={() => setShowsExtraContent((s) => !s)}
                           sx={{ bgcolor: "transparent", color: "#162c44", "&:hover": { bgcolor: "transparent" } }}
                        >
                           <Typography
                              fontFamily={"Bold"}
                              sx={{ "&:hover": { color: "#5c6c7c" }, textDecoration: "underline", fontSize: "12px" }}
                           >
                              {showsExtraContent ? "Ver menos" : "Ver más aquí"}
                           </Typography>
                           {/* <ExpandMoreIcon
                              style={{ transform: showsExtraContent ? "rotate(180deg)" : "rotate(0deg)" }}
                           /> */}
                        </Button>
                     </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                     <Box
                        ref={playerRef}
                        sx={{ mt: { display: "flex", md: "-60px", lg: "-100px", justifyContent: "center" } }}
                     >
                        <ReactPlayer
                           className="player"
                           playing={isPlaying}
                           url={
                              "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/SIROC+imagen+animada+imss.mp4"
                           }
                           loop
                           muted
                           width={"auto"}
                           height={"300px"}
                        />
                     </Box>
                  </Grid>
               </Grid>
            </Box>
            <Box
               mt={{ xs: -0, md: -10, lg: -12 }}
               position={"relative"}
               bgcolor={"transparent"}
               sx={{
                  backgroundImage: `url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/curvedBg.png)`,
                  backgroundSize: { xs: "100% 125%", lg: "100% 135%" },
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
               }}
            >
               <WhatIsServiceComponentSiroc
                  hasObjective
                  imageUrl={"url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/lecosySirocsec3.jpg)"}
                  objective={
                     <Typography sx={{ lineHeight: 1.2, fontSize: "14px" }}>
                        El SIROC es una plataforma establecida por el IMSS que permite a las empresas constructoras
                        registrar y gestionar sus obras conforme a lo establecido en el Reglamento del Seguro Social
                        Obligatorio para los Trabajadores de la Construcción por Obra o Tiempo Determinado. Desde  el 1
                        de septiembre de 2017, los patrones y sujetos obligados tienen la obligación de registrar sus
                        obras, las fases del proyecto y sus incidencias.
                        <br></br>
                        <br></br>
                        <br></br>
                        <strong style={{ fontSize: "1.5em" }}>Sujetos obligados:</strong>
                        <br></br>
                        <br></br>
                        Aquellos patrones dedicados a la actividad de la construcción, ya sean personas físicas o
                        morales, que: 
                        <ul>
                           <li>
                              Sean contratadas para llevar a cabo obras de construcción a precio alzado o bajo el
                              sistema de precios unitarios, con trabajadores a su servicio.
                           </li>
                           <li>
                              Cuenten con elementos propios y que celebren contratos con otras personas, para la
                              ejecución de una o más partes de la obra contratada por éstas.
                           </li>
                           <li>
                              Los propietarios de las obras de construcción, que directamente o a través de
                              intermediarios contraten a los trabajadores que intervengan en dichas obras.
                           </li>
                        </ul>
                     </Typography>
                  }
                  what={
                     <Typography
                        sx={{ px: { sm: 1 }, fontWeight: 700, fontSize: "1.1rem", lineHeight: 1.2, fontSize: "14px" }}
                     >
                        Cumplir con las obligaciones del SIROC no solo evita sanciones, sino que también permite un
                        mayor control en la seguridad social de los trabajadores, el monitoreo de incidencias y el
                        debido cumplimiento para asegurar que cada etapa de la obra se lleve a cabo sin contratiempos
                        legales y fiscales.
                     </Typography>
                  }
               />
            </Box>

            <Box
               position={"relative"}
               sx={{
                  backgroundSize: "100% 130%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  bgcolor: "transparent",
               }}
            >
               <ImageAccContainer
                  title={
                     <Typography
                        fontFamily={"bold"}
                        pl={{ xs: "20px", sm: "100px", md: "85px" }}
                        fontSize={{ xs: "30px", lg: "35px" }}
                        sx={{ borderBottom: 5, borderColor: "#8A95A1" }}
                     >
                        ¿Cómo funciona nuestro{" "}
                        <Typography fontFamily={"ExtraBold"} fontSize={{ xs: "30px", lg: "35px" }} lineHeight={1.1}>
                           Módulo de SIROC?
                        </Typography>
                     </Typography>
                  }
                  direction="row-reverse"
                  alternComponent={
                     <Box>
                        <StyledAccordionComponentImage
                           title="Cumplimiento"
                           content={SirocBodyContent} // Aquí va el contenido incluyendo el título principal
                           selectedAnswer={selectedText}
                           setSelectedAnswer={setSelectedText}
                           setSelectedImage={setImageAccContainerUrl}
                           setSelectedSubTitleR={setSelectedSubTitleR}
                           //agregar
                           mainTitleContent={styledAccContent.details}
                           mainSubTitleRContent={styledAccContent.subTitleR}
                           mainImageContent={styledAccContent.imageUrl}
                        />
                     </Box>
                  }
                  imageUrl={imageAccContainerUrl}
                  subTitleR={selectedSubTitleR}
                  subComponent={
                     <Stack direction={"column"} spacing={2}>
                        {selectedText}
                     </Stack>
                  }
               />
            </Box>

            <Grid
               container
               sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/SECCION-6-Principles-features+1.png)",
                  backgroundSize: "fit",
                  backgroundRepeat: "no-repeat",
               }}
               bgcolor="#e8ecef"
            >
               <Grid
                  item
                  xs={12}
                  sm={3}
                  md={4}
                  lg={5}
                  direction={{ xs: "column", sm: "row" }}
                  textAlign="center"
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     textAlign: "center",
                     py: { md: 5, lg: 0 },
                  }}
               >
                  <AnimationOnScroll
                     animateIn=" animate__slideInRight"
                     animateOnce
                     animatePreScroll={false}
                     duration={0.75}
                     delay={300}
                  >
                     <Box
                        component="img"
                        src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/1162356_ORH8S61+1+(2).png"
                        sx={{
                           marginLeft: { md: "-150px", lg: "auto" },
                           marginRight: { sm: "-50px", md: "-200px" },
                           marginBottom: { xs: "-50px", sm: 0 },
                           width: "auto",
                           height: { xs: "200px", sm: "250px", md: "450px" },
                           backgroundSize: "cover",
                           backgroundAttachment: "fixed",
                           justifyContent: "center",
                           transform: {
                              xs: showContent ? "translate(0vw,0vw)" : "translate(0vw,0vw)",
                              sm: showContent ? "translate(-4vw,0vw)" : "translate(6vw,3vw)",
                              md: showContent ? "translate(-4vw,0vw)" : "translate(4vw,3vw)",
                              lg: showContent ? "translate(-8vw,0vw)" : "translate(0vw,2vw)",
                           },
                           transition: "transform 0.3s",
                           mt: { xs: 4, sm: 0 },
                        }}
                     ></Box>
                  </AnimationOnScroll>
               </Grid>
               <Grid
                  item
                  xs={12}
                  sm={9}
                  md={8}
                  lg={7}
                  sx={{
                     justifyContent: "center",
                     alignItems: "center",
                     transform: {
                        xs: showContent ? "translate(0vw,0vw)" : "translate(0vw,0vw)",
                        sm: showContent ? "translate(0vw,0vw)" : "translate(0vw,0vw)",
                        md: showContent ? "translate(0vw,0vw)" : "translate(0vw,-4vw)",
                        lg: showContent ? "translate(-6vw,0vw)" : "translate(0vw,-2vw)",
                     },
                     transition: "transform 0.3s",
                  }}
               >
                  <Stack
                     p={5}
                     pb={0}
                     direction={{ xs: "column", sm: "row" }}
                     sx={{
                        justifyContent: { sm: "space-evenly", md: "space-between" },
                        maxWidth: "800px",
                        transform: {
                           xs: showContent ? "translate(0vw,0vw)" : "translate(0vw,0vw)",
                           sm: showContent ? "translate(0vw,0vw)" : "translate(0vw,-3vw)",
                           md: showContent ? "translate(0vw,0vw)" : "translate(0vw,0vw)",
                           lg: showContent ? "translate(0vw,0vw)" : "translate(-4vw,0vw)",
                        },
                        transition: "transform 0.3s",
                     }}
                  >
                     <Box
                        sx={{
                           pl: "1vw",
                           pb: { xs: "20px" },
                           transform: {
                              xs: showContent ? "translate(0vw,0vw)" : "translate(0vw,0vw)",
                              sm: showContent ? "translate(0vw,0vw)" : "translate(0vw,0vw)",
                              md: showContent ? "translate(0vw,0vw)" : "translate(0vw,0vw)",
                              lg: showContent ? "translate(0vw,0vw)" : "translate(0vw,0vw)",
                           },
                           transition: "transform 0.3s",
                        }}
                     >
                        <AnimationOnScroll
                           animateIn=" animate__slideInUp"
                           animateOnce
                           animatePreScroll={false}
                           duration={0.75}
                           delay={750}
                        >
                           <Typography
                              sx={{
                                 color: "#162C44",
                                 fontSize: { xs: "21px", sm: "21px", md: "35px" },
                                 textAlign: { xs: "center", sm: "left" },
                                 fontWeight: "bold",
                                 lineHeight: 1,
                              }}
                           >
                              ¿Qué relación tiene con
                           </Typography>
                           <Typography
                              sx={{
                                 color: "#162C44",
                                 fontSize: { xs: "24px", sm: "24px", md: "38px" },
                                 textAlign: { xs: "center", sm: "left" },
                                 fontWeight: 900,
                                 lineHeight: 1,
                              }}
                           >
                              la subcontratación?
                           </Typography>
                        </AnimationOnScroll>
                     </Box>
                     <Box
                        sx={{
                           textAlign: { xs: "center" },
                           pb: { xs: 2 },
                           pt: { md: 2 },
                           transform: {
                              xs: showContent ? "translate(0vw,0vw)" : "translate(0vw,0vw)",
                              sm: showContent ? "translate(6vw,0vw)" : "translate(0vw,0vw)",
                              md: showContent ? "translate(4vw,0vw)" : "translate(0vw,0vw)",
                              lg: showContent ? "translate(3vw,0vw)" : "translate(-9vw,0vw)",
                           },
                           transition: "transform 0.3s",
                        }}
                     >
                        <AnimationOnScroll
                           animateIn="animate__zoomIn"
                           animateOnce
                           animatePreScroll={false}
                           duration={0.75}
                           delay={750}
                        >
                           <Typography
                              sx={{
                                 color: "#162C44",
                                 fontSize: "10px",
                                 transform: showContent ? "scale(0)" : "scale(1)",
                                 transition: "transform 0.3s",
                                 mb: -3,
                              }}
                           >
                              Ver más aqui
                           </Typography>
                           <IconButton
                              onClick={toggleContent}
                              sx={{
                                 transform: showContent ? "rotate(180deg)" : "rotate(0deg)",
                                 transition: "transform 0.3s",
                                 color: "#162C44", // Cambia el color del ícono
                              }}
                           >
                              <ExpandMoreIcon sx={{ fontSize: 50, strokeWidth: 2 }} /> {/* Tamaño y grosor */}
                           </IconButton>
                        </AnimationOnScroll>
                     </Box>
                  </Stack>

                  <Collapse in={showContent}>
                     <Box p={{ xs: 5, sm: 3, md: 5 }} pt={{ xs: 0 }} mx={{ xs: 2 }} mb={{ xs: 2 }}>
                        <Grid container spacing={1} sx={{ maxWidth: "700px" }}>
                           <Grid item xs={12} md={12}>
                              <Box
                                 sx={{
                                    overflow: "hidden",
                                 }}
                              >
                                 <Typography sx={{ color: "#162C44", textAlign: "justify" }}>
                                    La Ley Federal del Trabajo en su artículo 15 establece que las personas físicas o
                                    morales que proporcionen servicios especializados o ejecuten obras especializadas
                                    deben contar con un registro ante la Secretaría del Trabajo y Previsión Social, es
                                    decir, deben obtener su registro REPSE (Registro de Prestadoras de Servicios
                                    Especializados u Obras especializadas).<br></br> <br></br>El artículo 15-A de la LSS
                                    (Ley del Seguro Social), el artículo 29 BIS de LINFONAVIT (Ley del Instituto del
                                    Fondo Nacional de la Vivienda para los Trabajadores), así como el artículo 26
                                    fracción XVI del CFF (Código Fiscal de la Federación) establece que la persona
                                    física o moral que contrate la prestación de servicios o la ejecución de obras con
                                    otra  persona física o moral que  incumpla las obligaciones en materia de fiscal,
                                    laboral, seguridad social, será responsable solidario en relación con los
                                    trabajadores utilizados para ejecutar dichas contrataciones.
                                 </Typography>
                              </Box>
                              <Box sx={{ pt: 5, display: "flex", flexDirection: "row-reverse" }}>
                                 <Button
                                    href="/repse"
                                    sx={{ mt: "-20px", borderRadius: 3, p: 1.5, "&:hover": { bgcolor: "#5c6c7c" } }}
                                 >
                                    <Box sx={{ display: "flex", alignItems: "center" }} mx={1}>
                                       <Typography fontWeight={"bold"} lineHeight={1.2}>
                                          Módulo REPSE
                                       </Typography>
                                    </Box>
                                 </Button>
                              </Box>
                              {/* <Box
                                 sx={{
                                    color: "#162C44",
                                    overflow: "hidden",
                                    padding: { xs: 1, sm: "16px" }, // Espacio alrededor del contenido
                                    //   borderTop: "4px solid #162C44", // Borde superior con color específico
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Sombra suave
                                    marginTop: "40px",
                                    textAlign: { xs: "center", sm: "justify" },
                                    paddingX: { xs: 3, sm: 5 },
                                    borderBottomRightRadius: "10px ",
                                    borderBottomLeftRadius: "10px ",
                                 }}
                              >
                                 <Typography
                                    my={2}
                                    color="white"
                                    fontWeight="extrabold"
                                    sx={{ color: "#162C44", fontFamily: "bold" }}
                                 >
                                    Controlar y gestionar correctamente la documentación de los contratistas y
                                    subcontratistas es clave para evitar sanciones, riesgos asociados a la
                                    responsabilidad solidaria y contingencias que afecten la operación de la empresa.
                                 </Typography>
                              </Box> */}
                           </Grid>
                        </Grid>
                     </Box>
                  </Collapse>
               </Grid>
            </Grid>

            <Box
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/background/Fondo-seccion-4.jpg)",
                  backgroundSize: "cover",
                  backgroundAttachment: "fixed",
                  pt: { xs: 10, md: 15 },
                  pb:"20px",
                  px: { xs: 3, md: 10 },
                  textAlign: { xs: "center", md: "left" }, // Centrado en móvil
               }}
            >
               <Typography sx={{ color: "white", textAlign: "center", fontSize: { sm: "18px", md: "24px" } }}>
                  <strong>Garantice una obra segura</strong> y en cumplimiento con nuestro módulo SIROC,{" "}
                  <strong>asegurando la trazabilidad y la materialidad</strong> correspondiente.
               </Typography>
               <Box display={"flex"} justifyContent={"center"} p={{ xs: 4, lg: 5 }}>
                     <Button
                        sx={{ bgcolor: "white", "&:hover": { bgcolor: "#8A95A1" }, px: 2, py: 1 }}
                        onClick={() => {
                           const element = document.getElementById("plans");
                           element.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                              inline: "center",
                           });
                        }}
                     >
                        <Typography color={"#162c44"} fontFamily={"bold"} sx={{ lineHeight: 1, fontSize: "0.875rem" }}>
                           Quiero el módulo SIROC para mi empresa
                        </Typography>
                     </Button>
                  </Box>
            </Box>

            <Stack
               direction={"column"}
               spacing={3}
               p={{ xs: 3, lg: 10 }}
               sx={{
                  // backgroundImage:
                  //    "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Fondo+beneficios.png)",
                  backgroundPosition: "center top",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
               }}
            >
               <Box display={"flex"} justifyContent={"center"} sx={{ pb: 5 }}>
                  <Typography
                     textAlign={"center"}
                     color={"#162c44"}
                     fontFamily={"bold"}
                     fontSize={{ xs: "1.6rem", lg: "2rem" }}
                     sx={{ lineHeight: 1.4, pt: 6 }}
                  >
                     Principales características de nuestro <br></br>
                     <Typography
                        sx={{
                           fontSize: { xs: "1.6rem", lg: "2rem" },
                           fontFamily: "bold",
                           borderBottom: 5,
                           lineHeight: 1.4,
                           display: "inline-block",
                        }}
                     >
                        módulo SIROC
                     </Typography>
                  </Typography>
               </Box>

               <Grid container spacing={0}>
                  {SirocBenefits.map((benefit, i) => {
                     return (
                        <Grid
                           item
                           md={12}
                           sx={{
                              display: "flex",
                              lignItems: "center",
                              justifyContent: "center",
                              alingSelf: "center",
                           }}
                        >
                           <Box
                              id={`section${i + 1}`}
                              key={benefit.title}
                              sx={{
                                 display: "flex",
                                 flexDirection: {
                                    xs: "column",
                                    sm: "column",
                                    md: i % 2 === 0 ? "row" : "row-reverse",
                                 },
                                 alignItems: "center",
                                 justifyContent: "center",
                                 alingSelf: "center",
                                 alignContent: "center",
                                 py: 1,
                                 px: 0,
                                 textAlign: "justify",
                                 minWidth: { md: "850px", lg: "1040px" },
                              }}
                           >
                              {descriptionZone(benefit, i)}
                              {ServiceScreenVideo(benefit, i % 2 === 0)}
                           </Box>
                        </Grid>
                     );
                  })}
               </Grid>

               <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  sx={{ pr: { xs: "30px", sm: "54px", md: "65px", lg: "58px" } }}
               >
                  <ContactConsultantButton />
               </Box>
            </Stack>
            <PlanInversion
               title="Plan de inversión"
               subtitle="Módulo SIROC"
               iconSrc="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/ICONOS+de+m%C3%B3dulos+LECOSY_REPSE+%26+SIROC-09+1.svg"
               monthlyPrice="$1,050.00"
               infoMonthlyPrice="* Hasta 100 GB de almacenamiento en la estructura documental."
               uniqueCostDescription="Costo único de proceso de implementación:"
               uniqueCost="$2,700.00"
               firsText="Para asegurar un funcionamiento óptimo del sistema, se requiere un proceso de implementación inicial que incluye:"
               implementationSteps={[
                  { title: "Capacitación", description: "Capacitación sobre el uso de la plataforma a los responsables de su gestión." },
                  { title: "Configuración inicial", description: "Configuración personalizada de la plataforma adaptada a los servicios de la empresa." },
               ]}
               secondText="Esta configuración inicial depende de la estructura específica de cada organización, le invitamos a proporcionarnos información sobre su empresa para elaborar una estimación detallada del costo de implementación."
               footerNote={
               <>
                  *Los precios no incluyen IVA.
                  <br />
                  Los precios están expresados en pesos mexicanos.
                  <br />
                  Solicite más información enviando correo a{" "}
                  <a href="mailto:contacto@lecosy.com.mx" style={{ color: "white", textDecoration: "underline" }}>
                     contacto@lecosy.com.mx
                  </a>
               </>
               }
               buttonLink="/contacto"
               textButtonLink="INVERTIR AHORA"

            />
            <Box
               sx={{
                  minHeight: "570px",
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Figures/Figura-5.png)",
                  backgroundSize: { xs: "0% 0%", md: "55% 100%" },
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "bottom right",
               }}
            >
               <Box
                  height={"100%"}
                  width={"400px"}
                  position={"absolute"}
                  right={0}
                  sx={{
                     backgroundImage: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Capa_1.png)",
                     backgroundSize: "contain",

                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "center",
                     display: { xs: "none", sm: "none", md: "inline" },
                  }}
               />
               <Typography
                  fontFamily={"bold"}
                  color={"primary"}
                  sx={{
                     px: { xs: 2, md: 10 },
                     pt: { md: 8, lg: 20 },
                     fontSize: { xs: "30px", md: "40px" },
                  }}
               >
                  Preguntas frecuentes
               </Typography>
               <Box ref={sectionRef}>{QuestionAccordion(SirocQuestions, true)}</Box>
            </Box>
            {ScrollToTopButton(window)}
            <Footer />
         </ScrollToTop>
      </ThemeConfig>
   );
};
