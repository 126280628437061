import { ThemeConfig } from "../config/theme.config";
import ScrollToTop from "../wrapper/scrollToTop";
import { Box, Button, Collapse, Stack, Typography, Grid, Icon, SvgIcon } from "@mui/material";
import { Footer, NavBar } from "../components";
import ServiceTitleSection from "../components/ServiceTitleSection";
import { descriptionZone, imageZone } from "./ServicesScreen";
import VideoContainer from "../components/VideoContainer";
import { StyledAccordionComponent } from "../components/StyledAccordion/styledAccordionComponent";
import { GovernanceBodyContent, GovernanceBodyExtraContent } from "../const/StyledAccordionContent";
import { GovernanceBenefits } from "../const/ServicesBenefits";
import { ScrollToTopButton } from "../components/scrollToTopButton";
import WhatIsServiceComponent from "../components/WhatIsServiceComponent";
import AdditionalSteps from "../components/StyledAccordion/AdditionalSteps";
import ServiceImportanceSection from "../components/ServiceImportanceSection";
import { QuestionAccordion } from "../components/questionAccordion";
import { GovernanceQuestions } from "../const/AccordionQuestions";
import ServiceJustifySection from "../components/ServiceJustifySection";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContactConsultantButton from "../components/contactConsultantButton";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./gradientBoxes.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import PlanInversion from "../components/PlanInversion";

export const GovernanceCorpScreen = () => {
   const styledAccContent = {
      details: (
         <Typography textAlign={"justify"} key={"mainTitleContent"}>
            Registramos en detalle el perfil de la empresa y su estructura de gobierno. Realizamos diagnósticos precisos
            para entender las necesidades únicas de la organización.
         </Typography>
      ),
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/videos/1.+REGISTRO+PRECISO+DE+SU+EMPRESA.mp4",
   };
   const [showsExtraContent, setShowsExtraContent] = useState(false);
   const [selectedText, setSelectedText] = useState(styledAccContent.details);
   const [videoContainerUrl, setVideoContainerUrl] = useState(styledAccContent.videoUrl);
   const navigate = useNavigate();

   return (
      <ThemeConfig>
         <ScrollToTop>
            <NavBar />
            <Box zIndex={-1} sx={{ mt: -14 }}>
               <Box
                  position={"realtive"}
                  top={0}
                  sx={{
                     width: "100%",
                     height: { xs: "450px", md: "610px", lg: "700px" },
                     overflow: "hidden",
                     mt: "0px",
                  }}
               >
                  <video
                     className="gcBanner"
                     autoPlay
                     loop
                     muted
                     style={{
                        opacity: 0.3,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "top",
                     }}
                  >
                     <source
                        src={
                           "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/videos/Banner+GOBIERNO+CORPORATIVO.mp4"
                        }
                        type="video/mp4"
                     />
                  </video>
               </Box>

               <Box
                  sx={{
                     position: "relative",
                     pt: { xs: 5, md: 0 },
                     mt: { xs: "-370px", sm: "-380px", md: "-500px", lg: "-588px" },
                  }}
               >
                  <ServiceTitleSection
                     title={"Gobierno Corporativo"}
                     phrase={"Un nuevo paradigma en la gestión empresarial"}
                     icon={
                        "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Gobierno+Corporativo+copia.svg)"
                     }
                  />
               </Box>
            </Box>

            <Box
               width={{ xs: "85%", md: "70%", lg: "100%" }}
               mx={{ xs: "auto", md: "auto" }}
               sx={{
                  backgroundImage: {
                     lg: `url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/curvedBg.png)`,
                  },
                  backgroundSize: "100% 135%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  overflowX: "hidden",
                  pt: { xs: 6, sm: 8, md: 5, lg: 10 },
                  position: "relative",
               }}
               mt={{ lg: -10 }}
            >
               <ServiceJustifySection
                  title={
                     <Typography
                        flex={1}
                        textAlign={"justify"}
                        alignSelf={"center"}
                        px={{ xs: 0, sm: 5, lg: 10 }}
                        sx={{ color: "#162c44", fontSize: { md: "18px" } }}
                        pt={{ lg: 10 }}
                     >
                        En el núcleo de las organizaciones reside el sistema de Gobierno Corporativo, en Lecosy, hemos
                        perfeccionado un Módulo{" "}
                        <strong>diseñado para potenciar cada aspecto de su estructura y gestión...</strong>
                     </Typography>
                  }
                  phrase={
                     <Typography
                        textAlign={"center"}
                        fontFamily={"bold"}
                        fontSize={"25px"}
                        mx={{ lg: 10 }}
                        sx={{ lineHeight: 1.2, py: 2, color: "#162c44" }}
                     >
                        Su socio estratégico para un Gobierno Corporativo{" "}
                        <Typography
                           sx={{ lineHeight: 1.2 }}
                           fontFamily={"ExtraBold"}
                           fontSize={"25px"}
                           display={"inline"}
                        >
                           <u style={{ textDecorationColor: "#8A95A1" }}>efectivo</u> y{" "}
                           <u style={{ textDecorationColor: "#8A95A1" }}>transparente</u>
                        </Typography>
                     </Typography>
                  }
                  imageUrl={
                     "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/29+2.png"
                  }
               />
            </Box>
            <Box
               position={"relative"}
               zIndex={1}
               sx={{
                  backgroundImage: {
                     lg: `url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/curvedBg.png)`,
                  },
                  backgroundSize: "100% 130%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  bgcolor: "transparent",
                  overflowX: "hidden",
               }}
            >
               <VideoContainer
                  title={
                     <Typography
                        maxWidth={{ xs: "90vw" }}
                        fontFamily={"bold"}
                        pl={{ xs: "60px", md: "85px" }}
                        fontSize={{ xs: "28px", lg: "35px" }}
                        sx={{ borderBottom: 5, borderColor: "#8A95A1", paddingBottom: "5px", lineHeight: 1.1 }}
                     >
                        ¿Cómo funciona el{" "}
                        <Typography fontFamily={"ExtraBold"} fontSize={{ xs: "28px", lg: "30px" }} lineHeight={1.1}>
                           Módulo de Gobierno Corporativo?
                        </Typography>
                     </Typography>
                  }
                  direction="row-reverse"
                  alternComponent={
                     <Box>
                        <StyledAccordionComponent
                           title="1. Registro preciso de su empresa"
                           content={GovernanceBodyContent}
                           selectedAnswer={selectedText}
                           setSelectedAnswer={setSelectedText}
                           setSelectedVideo={setVideoContainerUrl}
                           mainTitleContent={styledAccContent.details}
                           mainVideoContent={styledAccContent.videoUrl}
                        />
                        <Box px={{ xs: 10, lg: 10 }} py={5}>
                           <Button
                              onClick={() => setShowsExtraContent((s) => !s)}
                              sx={{ bgcolor: "transparent", color: "#162c44", "&:hover": { bgcolor: "transparent" } }}
                           >
                              <ExpandMoreIcon
                                 style={{ transform: showsExtraContent ? "rotate(180deg)" : "rotate(0deg)" }}
                              />
                              <Typography fontFamily={"ExtraBold"} sx={{ "&:hover": { color: "#5c6c7c" } }}>
                                 {showsExtraContent ? "Ver menos" : "Ver más"}
                              </Typography>
                           </Button>
                        </Box>
                     </Box>
                  }
                  videoUrl={videoContainerUrl}
                  videoPlayerProps={{ width: { lg: "80%" }, mx: "10%" }}
                  subComponent={
                     <Stack direction={"column"} spacing={2} px={{ xs: 1, md: 2 }} mx={"5%"}>
                        {selectedText}
                     </Stack>
                  }
               />
               <Collapse in={showsExtraContent}>
                  <Box
                     mt={{ xs: -15, lg: -10 }}
                     pb={20}
                     position={"relative"}
                     zIndex={1}
                     sx={{
                        backgroundImage: {
                           lg: `url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/curvedBg.png)`,
                        },
                        backgroundSize: "100% 130%",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        overflowX: "hidden",
                     }}
                  >
                     <Box display={"flex"} flexWrap={"wrap"} px={{ xs: 5 }}>
                        {GovernanceBodyExtraContent.map((content, i) => {
                           return (
                              <Box
                                 maxWidth={{ xs: "380px", sm: "250px", md: "250px", lg: "300px" }}
                                 p={2}
                                 m={2}
                                 key={content.title}
                                 mx={"auto"}
                              >
                                 <AdditionalSteps {...content} index={i + 6} />
                              </Box>
                           );
                        })}
                     </Box>
                  </Box>
               </Collapse>
            </Box>
            <Box zIndex={1} mt={{ lg: -15 }}>
               <WhatIsServiceComponent
                  what=<Typography>
                     Nuestro Módulo de Gobierno Corporativo está diseñado para potenciar y optimizar su sistema de
                     gobierno.
                     <br />
                     <br />
                     Imagine un entorno donde la gestión de reuniones, presenciales o virtuales, se convierte en un
                     proceso fluido y altamente eficiente, promoviendo una colaboración efectiva en cualquier momento y
                     lugar. En Lecosy, hacemos esta visión realidad.
                     <br />
                     <br />
                     <strong> Nuestra solución</strong> no solo impulsa la eficiencia en el Consejo de Administración,
                     Comités de Apoyo y Comité Directivo, sino que también{" "}
                     <strong>
                        garantiza un acceso completo y seguro a documentos confidenciales, proporcionando la
                        tranquilidad y confianza que su equipo merece.
                     </strong>
                  </Typography>
                  hasObjective
                  imageUrl={
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/SITIO-FOTOGRAFIAS-gobierno-corp.png)"
                  }
                  objective=<Typography sx={{ px: { sm: 2 } }}>
                     Es una{" "}
                     <strong>
                        solución intuitiva y eficaz diseñada para satisfacer las necesidades de los Órganos de Gobierno
                        de las entidades.
                     </strong>{" "}
                     Sirve para gestionar de manera eficiente la documentación relacionada con el sistema de gobierno
                     corporativo, ofreciendo almacenamiento centralizado, estructuración, seguimiento y acceso sencillo
                     a la información necesaria para la toma de decisiones.
                  </Typography>
               />
            </Box>
            <ServiceImportanceSection
               title={
                  <Typography
                     fontFamily={"bold"}
                     fontSize={{ xs: "26px", md: "36px" }}
                     maxWidth={{ xs: "100%", sm: "360px" }}
                     sx={{
                        lineHeight: 1.3,
                        px: { xs: "7%", sm: "0" },
                        textAlign: { xs: "center", sm: "center", md: "left" },
                     }}
                  >
                     ¿Por qué es <strong style={{ fontFamily: "extraBold" }}>importante contar con él?</strong>
                  </Typography>
               }
               description={
                  <Stack
                     direction={"column"}
                     spacing={2}
                     flex={2}
                     sx={{ px: { xs: 1.5, sm: 5, lg: 0.5 }, py: { xs: 3 } }}
                  >
                     <Typography textAlign={"justify"}>
                        La digitalización y administración mediante un software especializado en tareas de gobierno
                        corporativo dentro de una empresa representa una ventaja estratégica. Esta implementación no
                        solo asegura una gestión documental altamente eficiente, sino que también garantiza un acceso
                        seguro a información de carácter confidencial.
                     </Typography>
                     <Typography textAlign={"justify"}>
                        Ofrece una trazabilidad completa de todas las acciones y actividades llevadas a cabo, cumpliendo
                        rigurosamente con los estándares de seguridad de la información. No obstante, su valor va más
                        allá: optimiza la gestión global de datos, fomenta un intercambio fluido de conocimientos,
                        evaluando y mejorando constantemente el desempeño de los órganos de gobierno, contribuyendo así
                        al crecimiento y la solidez de la organización.
                     </Typography>
                  </Stack>
               }
               phrase={
                  <Typography
                     fontSize={{ xs: "1.1rem", lg: "1.2rem" }}
                     textAlign={"center"}
                     pt={{ xs: 10, lg: 8 }}
                     px={{ xs: 3, sm: 5, md: 20 }}
                  >
                     Descubra el poder de un <strong>Gobierno Corporativo inteligente.</strong> Control, eficiencia y
                     seguridad reunidos en nuestro Módulo de Gobierno Corporativo, donde{" "}
                     <strong>la gestión se transforma en sinergia empresarial.</strong>
                  </Typography>
               }
               additionalComponent={
                  <Box display={"flex"} justifyContent={"center"} p={{ xs: 4, lg: 5 }}>
                     <Button
                        sx={{ bgcolor: "white", "&:hover": { bgcolor: "#8A95A1" }, px: 2, py: 1 }}
                        onClick={() => {
                           const element = document.getElementById("plans");
                           element.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                              inline: "center",
                           });
                        }}
                     >
                        <Typography color={"#162c44"} fontFamily={"bold"} sx={{ lineHeight: 1, fontSize: "0.875rem" }}>
                           Quiero el módulo Gobierno Corporativo para mi empresa
                        </Typography>
                     </Button>
                  </Box>
               }
            />
            <Stack
               direction={"column"}
               spacing={3}
               p={{ xs: 3, lg: 10 }}
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Fondo+beneficios.png)",
                  backgroundPosition: "center top",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  overflowX: "hidden",
               }}
            >
               <Box display={"flex"} justifyContent={"center"}>
                  <Typography
                     textAlign={"center"}
                     color={"#162c44"}
                     fontFamily={"bold"}
                     fontSize={{ xs: "30px", lg: "35px" }}
                     sx={{ borderBottom: 5, mb: { sm: 0, md: 4 }, lineHeight: 1.4, pt: 8, mx: 5 }}
                  >
                     Principales características
                  </Typography>
               </Box>
               <Grid container>
                  {GovernanceBenefits.map((benefit, i) => {
                     return (
                        <Grid
                           item
                           md={12}
                           sx={{
                              display: "flex",
                              lignItems: "center",
                              justifyContent: "center",
                              alingSelf: "center",
                           }}
                        >
                           <Box
                              columnGap={{ sm: 0, md: 4, lg: 0 }}
                              id={`section${i + 1}`}
                              key={benefit.title}
                              sx={{
                                 display: "flex",
                                 flexDirection: {
                                    xs: "column",
                                    md: i % 2 === 0 ? "row" : "row-reverse",
                                 },
                                 alignItems: { md: "center" },

                                 textAlign: "justify",

                                 py: { xs: 4, sm: 2, md: 0 },
                                 px: { xs: 1, sm: 4 },
                                 maxWidth: { md: "1000px" },
                                 minWidth: { lg: "1240px" },
                              }}
                           >
                              {descriptionZone(benefit, i)}
                              {imageZone(benefit, i % 2 === 0)}
                           </Box>
                        </Grid>
                     );
                  })}
               </Grid>
               <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  sx={{ pr: { xs: "30px", sm: "54px", md: "65px", lg: "58px" } }}
               >
                  <ContactConsultantButton />
               </Box>
            </Stack>

            <PlanInversion
               title="Plan de inversión"
               subtitle="Módulo Gobierno Corporativo"
               iconSrc="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Gobierno+Corporativo+copia.svg"
               monthlyPrice="$2,900.00"
               uniqueCostDescription="Costo único de proceso de implementación:"
               firsText="Para asegurar un funcionamiento óptimo del sistema, se requiere un proceso de implementación inicial que incluye:"
               implementationSteps={[
                  { title: "Capacitación personalizada", description: "Para el coordinador de gobierno corporativo y los miembros del sistema." },
                  { title: "Configuración inicial", description: "Se ajusta a la estructura corporativa de la empresa." },
               ]}
               secondText="Esta configuración inicial depende de la estructura y necesidades específicas de cada organización, le invitamos a proporcionarnos información sobre su empresa para elaborar una estimación detallada del costo de implementación."
               footerNote={
               <>
                  *Los precios no incluyen IVA.
                  <br />
                  Los precios están expresados en pesos mexicanos.
                  <br />
                  Solicite más información enviando correo a{" "}
                  <a href="mailto:contacto@lecosy.com.mx" style={{ color: "white", textDecoration: "underline" }}>
                     contacto@lecosy.com.mx
                  </a>
               </>
               }
               buttonLink="/contacto"
               textButtonLink="Contáctenos"

            />      



            <Box
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Figures/Figura-5.png)",
                  backgroundSize: { xs: "0% 0%", md: "55% 100%" },
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "bottom right",
                  overflowX: "hidden",
               }}
            >
               <Box
                  height={{ lg: "600px" }}
                  width={"400px"}
                  position={"absolute"}
                  right={0}
                  my={"2%"}
                  sx={{
                     backgroundImage: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Capa_1.png)",
                     backgroundSize: "cover",
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "center",
                     overflowX: "hidden",
                  }}
               />
               <Typography
                  fontFamily={"bold"}
                  color={"primary"}
                  sx={{ px: { xs: 2, md: 10 }, pt: 5, fontSize: { xs: "30px", md: "40px" } }}
               >
                  Preguntas frecuentes
               </Typography>
               {QuestionAccordion(GovernanceQuestions, true)}
            </Box>
            {ScrollToTopButton(window)}
            <Footer />
         </ScrollToTop>
      </ThemeConfig>
   );
};
